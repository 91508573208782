import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthentificationInterceptor } from './shared/service/authentification.interceptor';
import { HttpErrorInterceptorService } from './shared/service/http-error-interceptor.service';

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>import('./Modules/layout/layout.module').then(m => m.LayoutModule),
    
  },
  {
    path: "test",
    loadChildren: () =>import('./Modules/test/test.module').then(m => m.TestModule),
    
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[ { provide: HTTP_INTERCEPTORS, useClass: AuthentificationInterceptor, multi: true },
     {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptorService,
    multi: true,
  },]
})
export class AppRoutingModule { }
