<div class="card border-0" (window:resize)="onResize($event.target.innerWidth)">
    <div draggable="true" class="card-body add-document">
        <div class="card-header bg-transparent col-12 d-flex justify-content-between txt">
            {{ 'documents_transmission.Ajouter_document'| translate }}
        </div>
        
        <div id="dropzone" (mouseenter)="hover(true)" (mouseleave)="hover(false)" [ngClass]=" isMobile?'drag-area-mobile': 'drag-area' " class="t pointer" (click)="selectFile($event)">
            <span *ngIf="!enter" class="card mb-2 img"><img [src]="icons.upload_gray" alt="Error"  /></span>
            <span *ngIf="enter" class="card mb-2 img-hover"><img [src]="icons.upload" alt="Error"  /></span>
            <span class="txt-gris" [ngClass]="{'change-color' : enter}"> <b class="reg-font">{{ 'ajout_documents.clique_upload'| translate }}</b><br>{{ 'ajout_documents.format'| translate }}<br>{{ 'ajout_documents.size'| translate }}</span>
            <input id="file-input" type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.pdf,.docx" class="file-upload " (change)="getFileInfo($event) " hidden (click)="$event.target.value=null"/>
        </div>
        <div *ngIf="errorSize" class="error p-3 m-1">
            <img [src]="icons.error" alt="Error" />
            {{ 'ajout_documents.taille_depassée'| translate }}
        </div>
        <div *ngIf="!errorSize && !!fileName" class="card mt-2 mb-2">
            <div class="card-body d-flex justify-content-between mt-2 mb-2">
                <div class="d-flex align-items-center">
                    <img [src]="icons.doc"  alt="Error" class="icon-doc" />
                    <span class="bold-font reg-font">{{fileName}}</span>
                </div>
                <div class="d-flex align-items-center mr-1">
                    <img (click)="removeDoc()" [src]="icons.trash" alt="Error" class="icon-sup pointer"  />
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 cancel" (click)="onCancelAction()">
            <div  class="reg-font">{{ 'shared.cancel' | translate }}</div>
        </div>
        <div class="col-6">
            <button kendoButton class="trans_btn reg-font" type="button" (click)="transfertDocuments()"
                [disabled]="!uploadedFile">{{ 'ajout_documents.transmettre_documents'| translate }}</button>
        </div>
    </div>
</div>