import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ARRAY_PREDECLARATION_STEPS, ARRAY_PREDECLARATION_STEPS_WITHOUT_TITLE, PREDECLARATION_STEPS } from 'src/app/shared/dictionnary/pre-declaration-steps';
import { ICONS } from 'src/app/shared/enum/icon';
import { NATURESINISTRE } from 'src/app/shared/enum/nature-sinistre';
import { SinistreDocumentModel } from 'src/app/shared/models/sinistre-document.model';
import { AuthentificationService } from 'src/app/shared/service/authentification.service';
import { ClaimPostService } from 'src/app/shared/service/claim-post-service';
import { ContractService } from 'src/app/shared/service/contract.service';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { SinistreSubjectService } from './sinistre-subject.service';

@Component({
  selector: 'app-sinistre-declaration',
  templateUrl: './sinistre-declaration.component.html',
  styleUrls: ['./sinistre-declaration.component.scss']
})
export class SinistreDeclarationComponent implements OnInit {
  icons= ICONS;
  claimPhoneNumber='';
  screenWidth:number=0;
  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
  public documentsList: Array<SinistreDocumentModel>= [];
  form = new FormGroup({
    date: new FormControl(null),
    nature: new FormControl('', Validators.required),
    circonstances: new FormControl('', Validators.required),
    blesseur: new FormControl(null, Validators.required),
    contact: new FormControl(''),
  });
  currentStep = 0;
  isMobile = false;
  userInformation = null;
  currentClaimData = null
  steps_list= PREDECLARATION_STEPS;
  sinistreTypes=[];
  authentification = this.authentificationService.getUser();
  steps = ARRAY_PREDECLARATION_STEPS;
  constructor(private translate: TranslateService,private authentificationService:AuthentificationService, private claimPostService: ClaimPostService, private sinistreSubjectService:SinistreSubjectService, private sharedDataService:SharedDataService, private contractService: ContractService) { }

  ngOnInit(): void {
    this.onResize(window.innerWidth);
    this.getAuthentification();
    this.getSinistresNatures();
    this.sinistreSubjectService.setPageIsDeclaration(true);
  }
  getAuthentification(){
      if(this.authentification){
      this.createNewClaimPost({
      cpContractIdentifier: this.authentification['contractId'],
      cpClientIdentifier: this.authentification['clientId']})
      this.getUserInformation(this.authentification['contractId'], this.authentification['clientId'])
      this.getPhoneNumber(this.authentification['contractId'], this.authentification['clientId']);

    }
  }
  receiveStep(event) {
    this.currentStep = event;
    this.scroll.nativeElement.scrollTop = 0; 
  }
   onResize(event) {
     this.screenWidth = event;
     if(event<1200){
      this.isMobile = true;
      this.steps = ARRAY_PREDECLARATION_STEPS_WITHOUT_TITLE;
      } else {
       this.isMobile = false;
       this.steps = ARRAY_PREDECLARATION_STEPS;
     }
  }
  createNewClaimPost(claim){
    this.claimPostService.save(claim).pipe(take(1)).subscribe(result=>{
    if(claim){
      this.currentClaimData = result;
      this.sinistreSubjectService.changeSinistreValue(this.currentClaimData)
    }  
    })
  }
  stepChange(event){
    event.preventDefault();
  }
  getUserInformation(contractId, clientId){
    this.contractService.getUserInformation(contractId,clientId).pipe(take(1)).subscribe(result => {
      this.userInformation = result;
    })
  }
  getSinistresNatures(){
    this.claimPostService.getClaimsNatures().pipe(take(1)).subscribe(result=>{
      const autre=result.filter(x=>x.cnCode==NATURESINISTRE.aut);
      const list=result.filter(x=>x.cnCode!==NATURESINISTRE.aut);
      this.sinistreSubjectService.setSenistresTypes(list.concat(autre));
    })
  }
  getSinistre(){
    this.sinistreSubjectService.newSinistre.subscribe(result=>{
      this.documentsList= result.cpDocumentList;
    })
  }
  public scrollBottom() {
    this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
  }
  getPhoneNumber(contractId,clientId){
    this.claimPostService.getPhoneNumber(contractId,clientId).pipe(take(1)).subscribe(result=>{
      this.claimPhoneNumber= result;
    })
  }
}
