import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { SinistreDeclarationModule } from '../sinistre-declaration/sinistre-declaration.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { InformationPopupComponent } from './information-popup/information-popup.component';



@NgModule({
  declarations: [LayoutComponent, NotAuthorizedComponent, InformationPopupComponent],
  imports: [
    CommonModule,
    SharedModule,
    LayoutRoutingModule,
    SinistreDeclarationModule
  ],
  exports: [LayoutComponent],
})
export class LayoutModule { }
