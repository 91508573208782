<div>
<div class="overflow-auto" style="height: 400px!important;" (clickOutside)="clickOut()">
<div *ngIf="pageName=='condition'" class="page-content">
<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;vertical-align:baseline'><b><span
style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Conditions&nbsp;générales&nbsp;d'utilisation&nbsp;du
site&nbsp;internet</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Les présentes conditions générales
d’utilisation (ci-après dénommées « CGU ») ont pour objet de déterminer les
règles d’utilisation du site internet www.</span><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>sinistre-MRH-VYV.cedcloud.com</span><span
style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'> (ci-après dénommé
le « Site ») édité par la société CED FRANCE (ci-après dénommée la « Société
»).</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Toute utilisation du Site est
soumise à l’acceptation des présentes CGU.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>En accédant au Site, en tant que
visiteur et/ou utilisateur, vous acceptez sans condition ni réserve
l’intégralité des CGU définies ci-après. Si vous ne souhaitez pas accepter les
présentes CGU, nous vous demandons de ne pas accéder au Site. Toute utilisation
du Site doit se faire dans le respect des présentes CGU.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
<b>Article 1. DEFINITIONS</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
Les termes débutant par une majuscule au sein des présentes CGU, qu’ils soient
utilisés indifféremment au singulier ou au pluriel, auront la signification qui
leur est donnée ci-après :</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
« Site » désigne le site internet édité et fourni par la Société dont l’adresse
est la suivante : </span><span style='color:black'><span class=MsoHyperlink><span
style='font-size:10.0pt;font-family:"Arial",sans-serif'>https://www.</span></span><span
class=MsoHyperlink><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>
sinistre-MRH-VYV.cedcloud.com</span></span><span class=MsoHyperlink><span
style='font-size:10.0pt;font-family:"Arial",sans-serif'> </span></span></span><span
style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'> donnant
accès aux services, qui est accessible gratuitement par l’Utilisateur sur son
terminal Apple iOS ou Android. Le Site comprend également les contenus, les
logiciels, les programmes, les outils (de programmation, de navigation, …), les
bases de données, les systèmes d’exploitation, la documentation et tous autres
éléments et services qui le composent, les mises à jour et les nouvelles
versions qui peuvent être apportées au Site par la Société.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
« Contenu » désigne, sans que cette liste ne soit limitative, la structure du
Site, le contenu éditorial, les dessins, les illustrations, les images, les
photographies, les chartes graphiques, les marques, les logos, les sigles, les
dénominations sociales, les œuvres audiovisuelles, les œuvres multimédias, les
contenus visuels, les contenus audio et sonores, ainsi que tout autre contenu
présent au sein du Site et/ou tout autre élément composant le Site.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
« Services » désignent les différentes fonctionnalités et services proposés par
le Site.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
« Utilisateur » ou « Vous » désigne une personne physique majeure ou mineure
ayant préalablement obtenu l’autorisation de ses parents ou de la personne
investie de l'autorité parentale, et accédant au Site pour ses besoins propres,
dans le cadre d’un usage personnel ou commercial.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<b>Article 2. OBJET DU SITE</b>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Le Site a pour objet de donner
accès à l’Utilisateur à un portail extranet lui permettant de compléter son
dossier, transmettre des photos de son sinistre et des documents justificatifs,
en vue de faciliter le travail de gestionnaire de sinistre de la Société. Le
Site permet à l’Utilisateur de transmettre plusieurs photos de pièces
endommagées.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<b>Article 3. ACCES AU SITE</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
Pour accéder et utiliser le Site, l’Utilisateur doit posséder un ordinateur, un
téléphone compatible ou un terminal mobile et un accès au réseau Internet.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
color:black'><br>
Le Site est accessible à l’adresse </span><span style='color:black'><a
href="https://www.sinistre-MRH-VYV.cedcloud.com"><span style='font-size:10.0pt;
font-family:"Arial",sans-serif'>https://www.</span><span style='font-size:10.0pt;
font-family:"Arial",sans-serif'>sinistre-MRH-VYV.cedcloud.com</span></a></span><span
style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'> depuis
l’espace personnel VYV CONSEIL.</span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'><br>
<br>
<b>Article 4. CONDITIONS FINANCIERES</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
L’utilisation du Site est proposée gratuitement aux Utilisateurs hors coûts
d’abonnement auprès de l’opérateur de téléphonie fixe ou mobile, hors coûts de
connexion et d’accès au réseau Internet et hors surcoût éventuel facturé pour
le chargement des données.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
<b>Article 5. APPLICATION DES CGU</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
Les CGU sont applicables à tout Utilisateur du Site et des Services.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>En transmettant les éléments de son
dossier à la Société, l’Utilisateur accepte les CGU accessibles depuis le Site.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>L'Utilisateur est invité à lire
attentivement et à chaque visite les CGU qui sont accessibles par hyperlien sur
la page d’accueil du Site et susceptibles d’être modifiées à tout moment et
sans préavis par la Société.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
<b>Article 6. PROPRIETE INTELLECTUELLE DU SITE</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
La Société est propriétaire exclusif de tous les droits de propriété
intellectuelle afférents au Site et à son Contenu.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Sans préjudice des dispositions de
l’article 7, aucune des dispositions des CGU ne peut être interprétée comme une
cession, un transfert, une vente, une concession, une licence, un prêt, une
location, une autorisation d'exploitation consentie directement ou
indirectement par la Société au profit de l'Utilisateur sur le Site, son
Contenu et/ou les Services.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
<b>Article 7. OBLIGATIONS DE L’UTILISATEUR</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
L’Utilisateur s’engage expressément&nbsp;:</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.0pt;text-align:justify;text-indent:-24.0pt;
line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>à utiliser le Site sur son équipement
exclusivement pour un usage personnel et non marchand ;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.0pt;text-align:justify;text-indent:-24.0pt;
line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>à ne pas modifier ou altérer le Site ou son
contenu, d’aucune manière&nbsp;;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.0pt;text-align:justify;text-indent:-24.0pt;
line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>à ne pas reproduire de façon permanente ou
provisoire le Site, en tout ou partie, par tout moyen et sous toute forme ;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.0pt;text-align:justify;text-indent:-24.0pt;
line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>à ne pas utiliser de logiciels ou de procédés
destinés à copier le Contenu sans l'autorisation préalable écrite de la
Société&nbsp;;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.0pt;text-align:justify;text-indent:-24.0pt;
line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>à ne pas procéder à toute adaptation,
modification, traduction, transcription, arrangement, compilation, décompilation,
assemblage, désassemblage, transcodage, ni appliquer la rétro-ingénierie (ou «
Reverse Engineering ») de tout ou partie du Site, des Services et/ou du Contenu
;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.0pt;text-align:justify;text-indent:-24.0pt;
line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>à ne pas mettre en place des systèmes
susceptibles de pirater le Site et/ou le Contenu, en tout ou partie, ou de
nature à violer les présentes CGU ;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.0pt;text-align:justify;text-indent:-24.0pt;
line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>à informer la Société dès la connaissance d'un
acte de piratage et en particulier de toute utilisation illicite ou non
contractuelle du Site et/ou du Contenu, quel que soit le mode de diffusion.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<b>Article 8. PROTECTION DES DONNEES PERSONNELLES</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
Dans le cadre de l’exploitation du Site, la Société met en œuvre des
traitements de données à caractère personnel et agit, à ce titre, en qualité de
responsable de traitement au sens du Règlement (UE) 2016/679 du 27 avril 2016
dit Règlement Général sur la Protection des Données («&nbsp;RGPD&nbsp;») et de
la Loi n°78-17 modifiée du 6 janvier 1978. Les traitements de données à
caractère personnel opérés ont notamment pour finalité de gérer et suivre les
différentes déclarations de sinistre des Utilisateurs. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>L’Utilisateur dispose des droits
suivants&nbsp;: droit d’accès, de rectification, d’effacement, droit de retirer
à tout moment son consentement le cas échéant, droit à la limitation du
traitement, droit d’opposition, droit à la portabilité, ainsi que le droit de
définir des directives quant au sort de ses données post mortem. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>L’Utilisateur peut exercer ses
droits auprès de la Société en contactant la Société dont les coordonnées sont
mentionnées dans les Mentions légales. S’il estime toutefois ne pas avoir
obtenu satisfaction, l’Utilisateur a le droit d’introduire une réclamation
auprès de la CNIL</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Pour plus d’informations et pour
l’exercice de ses droits relatifs à ses Données à caractère personnel,
l’Utilisateur est invité à consulter notre politique de confidentialité et
notre politique cookies.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%;font-family:"Arial",sans-serif'>Les coordonnées de notre
Délégué à la protection des données sont les suivantes&nbsp;: </span><a
href="mailto:dpo@eurexo-ced.fr"><span style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif'>dpo@eurexo-ced.fr</span></a><span
style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><b><span style='font-size:
10.0pt;font-family:"Arial",sans-serif;color:black'>Article 9. DISPONIBILITE DU
SITE</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
Le Site est en principe accessible 24 heures sur 24, 7 jours sur 7. Il est
proposé «&nbsp;en l’état&nbsp;», sans aucune garantie.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>La Société met le Site
régulièrement à jour. En cas de nécessité, la Société peut suspendre l’accès ou
le fermer pour une durée indéterminée, et ce à sa pleine et entière discrétion.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Une interruption ou une suspension
du Site et/ou des Services, programmée ou non, peut survenir notamment pour des
besoins de maintenance, de problèmes de réseau, de problèmes logiciels, de
problèmes de sécurité ou en cas de force majeure.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Aucun frais ni dédommagement ne
pourra être réclamé à la Société en cas d’interruption ou de suspension du Site
et/ou des Services.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
<b>Article 10. LIMITATION DE GARANTIE</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
L’accès et l’utilisation du Site se font aux risques et périls de
l'Utilisateur, aucune garantie quelle qu’elle soit n’est fournie par la Société.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Il appartient à tout Utilisateur de
prendre toutes les mesures appropriées de façon à protéger ses propres données
et/ ou logiciels stockés sur ses équipements informatiques et téléphoniques
contre toute atteinte.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>L'Utilisateur déclare connaître et
accepter les caractéristiques et les limites du réseau Internet et, notamment
les caractéristiques fonctionnelles et des performances techniques du réseau
Internet ; les problèmes liés à connexion et/ou d'accès au réseau Internet
et/ou aux sites web ; les problèmes liés à la disponibilité et à l'encombrement
des réseaux ; les problèmes liés à la défaillance ou à la saturation des
réseaux ; les problèmes liés au temps de transit, à l’accès aux informations
mises en ligne, aux temps de réponse pour afficher, consulter, interroger ou
autrement transférer des données ; les risques d’interruption ; l'absence de
protection de certaines données contre des détournements éventuels ou piratage
; les risques de contamination par d’éventuels virus circulant sur les dits
réseaux, etc. pour lesquelles la responsabilité de la Société ne saurait être
engagée.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
La Société ne peut être tenue pour responsable :</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt;line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>en cas de défaut, de perte, de retard ou
d’erreur de transmission de données qui sont indépendants de sa volonté ;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt;line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>de l'envoi de messages et/ou de données et à
une adresse fausse, erronée ou incomplète ;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt;line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>si des données ne lui parvenaient pas, pour
quelque raison que ce soit, ou si les données qu’elle reçoit étaient illisibles
ou impossibles à traiter ;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt;line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>au cas où l’Utilisateur ne parvenait à accéder
ou à utiliser le Site et/ou les Services pour quelque raison que ce soit ;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt;line-height:normal;background:white;vertical-align:baseline'><span
style='font-size:10.0pt;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
"Arial",sans-serif;color:black'>si, pour quelque raison que ce soit, la
connexion devait être interrompue.<br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Par ailleurs, la Société décline
toute responsabilité en cas de mauvaise utilisation du terminal et/ou
d'incident lié à l'utilisation du terminal lors de l’utilisation du Site. La
Société ne saurait en aucun cas être tenue responsable de tout dommage, de
quelque nature que ce soit, causé aux Utilisateurs, à leurs terminaux, à leurs
équipements informatiques et téléphoniques et aux données qui y sont stockées
ni des conséquences pouvant en découler sur leur activité personnelle,
professionnelle ou commerciale.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
<b>Article 11. EVOLUTION DES CONDITIONS GENERALES D'UTILISATION</b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
Les CGU applicables sont celles en vigueur à la date de la connexion et de
l’utilisation du Site par l’Utilisateur.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>La Société se réserve le droit de
modifier, à tout moment, tout ou partie, des dispositions des CGU sans préavis
ni information préalable des Utilisateurs, afin de les adapter aux évolutions
des Services, aux évolutions techniques, légales ou jurisprudentielles ou lors
de la mise en place de nouvelles prestations.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Les modifications éventuellement
apportées par la Société aux CGU seront portées à la connaissance des
Utilisateurs par leur simple mise en ligne. Elles sont réputées acceptées sans
réserve par tout Utilisateur qui accède au Site postérieurement à ladite mise
en ligne. La Société invite donc tout Utilisateur à consulter régulièrement les
CGU.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Tout nouveau Service intégrant de
nouvelles techniques ou nouvelles caractéristiques améliorant la qualité des
Services existants seront aussi soumis aux présentes CGU, sauf disposition
expresse contraire.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>La Société peut mettre fin à
l’utilisation du Site à tout moment.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><b><span style='font-size:
10.0pt;font-family:"Arial",sans-serif;color:black'>ARTICLE 12. LOI APPLICABLE</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
Les présentes CGU sont soumises à la loi française.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'>Tout litige ou contestation relatif
à l'exécution ou à l'interprétation du présent règlement qui n’aura pu être
réglé à l'amiable entre les parties sera soumis aux tribunaux compétents du
ressort des tribunaux français.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal;background:white;vertical-align:baseline'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif;color:black'><br>
<br>
Dernière mise à jour, le 3 avril 2023.</span></p>

</div>

<!-- Politique de confidentialité -->
<div class=WordSection1 *ngIf="pageName=='politique'" class="page-content"> 

    <div style='border:solid windowtext 1.0pt;padding:1.0pt 4.0pt 1.0pt 4.0pt'>

        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>Politique
        de confidentialité du site Internet </span></b><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>https://<span style='color:black'>www.</span></span></b><b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>sinistre-MRH-VYV.cedcloud.com</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        </div>
        
        <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>
        
        <p class=MsoNormal style='background:white'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#333333'>La présente politique de
        confidentialité détaille la politique de&nbsp;<b>CED FRANCE&nbsp;</b>:</span></p>
        
        <p class=MsoNormal style='background:white'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='background:white'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>Société à responsabilité limitée au
        capital social de 676&nbsp;224,51 €<br>
        Siège social&nbsp;: 2, rue Jacques Daguerre, 92500 RUEIL MALMAISON<br>
        SIREN : &nbsp;R.C.S NANTERRE <span class=AJvar>418&nbsp;501 524</span></span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#333333'>(Ci-après la <a
        name="_Hlk118447810">«&nbsp;<b>Société </b>&nbsp;» </a>ou «&nbsp;<b>CED</b>&nbsp;<b>FRANCE</b>
        ») en matière de données à caractère personnel recueillies sur le site </span><a
        href="https://www.sinistre-MRH-VYV.cedcloud.com"><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>https://www.</span><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>sinistre-MRH-VYV.cedcloud.com</span></a><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:#333333'> (ci-après
        le «&nbsp;<b>Site</b>&nbsp;») et s’applique à toutes les informations fournies
        par vous-même, ou recueillies par la Société lors de votre navigation sur le
        Site, conformément à la réglementation en vigueur en France en matière de
        données personnelles telle qu’issue de la loi « Informatique et Libertés&nbsp;»
        et du Règlement sur la protection des données dit «&nbsp;<b>RGPD</b>&nbsp;».</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#333333'>&nbsp;</span></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;background:
        white'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;color:#333333'>La
        présente politique de confidentialité a pour vocation d’informer les personnes
        utilisant le Site (ci-après les «&nbsp;<b>Utilisateurs&nbsp;</b>») de la
        manière dont nous collectons, utilisons et partageons les Données à caractère
        personnel que nous recueillons ou sommes susceptibles de recueillir dans le
        cadre des Traitements du Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#282828;background:white'>Il vous est possible de visiter ce site sans
        communiquer aucune information personnelle vous concernant. Le Site ne permet
        pas aux visiteurs de communiquer entre eux ou de publier des informations
        accessibles par d’autres internautes. </span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#282828;background:white'>Nous collectons uniquement vos données à
        caractère personnel si elles sont fournies par vous volontairement lors
        d’utilisation de notre formulaire de déclaration de sinistre. </span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>1/ Définitions</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Les
        termes employés ci-après ont, dans la présente politique de confidentialité, la
        signification suivante&nbsp;: </span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>«&nbsp;<b><i>Donnée
        à caractère personnel</i></b>&nbsp;» <b><i>ou «&nbsp;Données&nbsp;» </i></b>:
        Constitue une donnée à caractère personnel toute information relative à une
        personne physique </span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif;color:black;letter-spacing:.05pt'>identifiée ou
        qui peut être identifiée, directement ou indirectement, par référence à un
        numéro d'identification ou à un ou plusieurs éléments qui lui sont propres.
        Pour déterminer si une personne est identifiable, il convient de considérer
        l'ensemble des moyens en vue de permettre son identification dont dispose ou
        auxquels peut avoir accès le responsable du traitement ou toute autre personne.</span><u><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:blue;letter-spacing:.05pt'> </span></u><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .05pt'> </span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.1pt'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><i><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif;letter-spacing:-.2pt'> «&nbsp;Loi I&amp;L&nbsp;»</span></i></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        letter-spacing:-.2pt'>&nbsp;: Loi n°78-17 du 6 janvier 1978 relative à
        l’informatique, aux fichiers et aux libertés, modifiée.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><i><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif;color:black'>&nbsp;</span></i></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><i><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif;color:black'>«&nbsp;Personnes concernées&nbsp;»</span></i></b><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;: </span></b><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif;color:black'>Les personnes concernées par un
        Traitement de données personnelles sont celles auxquelles se rapportent les
        données qui font l'objet du Traitement.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><i><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;</span></i></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><i><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif;color:black'>«&nbsp;Responsable de traitement&nbsp;»</span></i></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;: Le responsable d'un traitement de données personnelles
        est, sauf désignation expresse par les dispositions législatives ou
        réglementaires relatives à ce traitement, la personne, l'autorité publique, le
        service ou l'organisme qui détermine ses finalités et ses moyens.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><i><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif;color:black'>«&nbsp;RGPD&nbsp;»</span></i></b><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;: </span></b><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif;color:black'>Règlement général sur la protection
        des données, Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
        avril 2016 relatif à la protection des personnes physiques à l'égard du
        traitement des données à caractère personnel et à la libre circulation de ces
        données, et abrogeant la directive 95/46/CE.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><i><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif;color:black'>«&nbsp;Sous-traitant&nbsp;»</span></i></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;: Le Sous-traitant est une personne traitant des données à
        caractère personnel pour le compte du Responsable du traitement, il agit sous
        l’autorité du Responsable du traitement et sur instruction de celui-ci. En
        l’espèce, désigne la personne physique ou morale, l'autorité publique, le
        service ou un autre organisme qui Traite des Données à caractère personnel pour
        le compte et selon les instructions de CED FRANCE conformément à l’article 4
        (8) du RGPD</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><b><i><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif;color:black'>«&nbsp;Traitement de données personnelles (ou Traitement)&nbsp;»</span></i></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&nbsp;: <span style='letter-spacing:-.2pt'>Toute opération ou tout
        ensemble d'opérations portant sur de telles données, quel que soit le procédé
        utilisé, et notamment la collecte, l'enregistrement, l'organisation, la
        conservation, l'adaptation ou la modification, l'extraction, la consultation,
        l'utilisation, la communication par transmission, diffusion ou toute autre
        forme de mise à disposition, le rapprochement ou l'interconnexion, ainsi que le
        verrouillage, l'effacement ou la destruction. </span></span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black;letter-spacing:-.2pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black;letter-spacing:-.2pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;vertical-align:
        baseline'><span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black;letter-spacing:-.2pt'>&nbsp;</span></p>
        
        <span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'><br
        clear=all style='page-break-before:always'>
        </span>
        
        <p class=MsoNormal style='line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>2/ Qui est le Responsable du Traitement
        de mes données personnelles ?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>La société CED FRANCE est le Responsable de
        Traitement qui collecte et gère les données à caractère personnel et propriétaire
        du Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Vous retrouverez ci-dessous les coordonnées
        postales et téléphoniques et également dans les Mentions Légales&nbsp;:</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='background:white'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>CED FRANCE&nbsp;:</span></b></p>
        
        <p class=MsoNormal style='background:white'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>Société à responsabilité limitée au
        capital social de 676&nbsp;224,51 €<br>
        Siège social&nbsp;: 2, rue Jacques Daguerre, 92500 RUEIL MALMAISON<br>
        SIREN : &nbsp;R.C.S NANTERRE <span class=AJvar><span style='font-style:normal'>418&nbsp;501
        524</span></span></span></p>
        
        <p class=MsoNormal style='background:white'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>Code APE&nbsp;: 6622 Z</span></p>
        
        <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>Téléphone&nbsp;:
        </span><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>+33 1 55
        47 12 82</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>3/ Quels sont les Traitements que
        nous mettons en œuvre&nbsp;?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:
        0cm;text-align:justify'><a href="https://www.sinistre-MRH-HM.cedcloud.com"><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;text-decoration:none'>Le
        site </span><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>sinistre-MRH-VYV.cedcloud.com</span></a><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'> <span
        style='color:black'>est un site Internet accessible depuis l’espace personnel
        VYV CONSEIL à l’adresse url<span class=apple-converted-space>&nbsp;</span></span></span><a
        href="https://www.sinistre-MRH-VYV.cedcloud.com"><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>https://www.sinistre-MRH-VYV.cedcloud.com</span></a><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'> <span
        style='color:black'>permettant de déclarer vos sinistres et de suivre vos
        sinistres déclarés.</span></span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Dans ce cadre, le traitement comprend la finalité de gestion de vos
        sinistres et les sous finalités suivantes&nbsp;:</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&#61485;</span><span style='font-size:10.0pt;line-height:115%'>            </span><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Déclaration et suivi de la gestion des sinistres.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&#61485;</span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif;color:black'>         Gestion de la lutte contre
        la fraude ;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&#61485;</span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif;color:black'>         Le bon fonctionnement et l’amélioration
        permanente de l’Application ;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>&#61485;</span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif;color:black'>         La gestion des demandes de
        droits issus du RGPD et de la Loi Informatique et Libertés modifiée.    </span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>4/ Quelles sont les finalités
        et bases légales des Traitements mis en œuvre ?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>CED FRANCE collecte uniquement les Données
        nécessaires pour les finalités explicites et déterminées ci-dessous&nbsp;:</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
        7.5pt;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt'><b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></b><span dir=LTR></span><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black;letter-spacing:.15pt'>La déclaration
        et le suivi des sinistres&nbsp;</span></b></p>
        
        <p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
        7.5pt;margin-left:0cm;text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#C00000;letter-spacing:.15pt'>En savoir
        plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Détails du traitement&nbsp;: </span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>-Déclaration des sinistres&nbsp;; </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>-Transmission des documents&nbsp;;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>-Gestion des sinistres&nbsp;;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>-Suivi/consultation de sinistre en cours et clos. </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Base légale</span></u></b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black;letter-spacing:.15pt'>&nbsp;: Exécution d’un
        contrat (</span><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:#333333'>contrat de délégation de gestion de sinistres en tant que
        sous-traitant et CGU du Site)</span><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black;letter-spacing:.15pt'>.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
        7.5pt;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt'><b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
        dir=LTR></span><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.15pt'>La gestion de lutte contre la fraude&nbsp;</span></b></p>
        
        <p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
        7.5pt;margin-left:0cm;text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#C00000;letter-spacing:.15pt'>En savoir
        plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Détails du traitement&nbsp;: </span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>-Analyse et détection des déclarations frauduleuses.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Base légale</span></u></b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black;letter-spacing:.15pt'>&nbsp;: Intérêt légitime
        de CED France.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
        7.5pt;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt'><b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></b><span dir=LTR></span><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black;letter-spacing:.15pt'>Le bon
        fonctionnement et l’amélioration permanente du Site</span></b></p>
        
        <p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
        7.5pt;margin-left:0cm;text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#C00000;letter-spacing:.15pt'>En savoir
        plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Détails du traitement</span></u></b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black;letter-spacing:.15pt'>&nbsp;: </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Assurer le bon fonctionnement et l’amélioration permanente du Site et de
        ses fonctionnalités. </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Bases légales</span></u></b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black;letter-spacing:.15pt'>&nbsp;: </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>-Notre intérêt légitime à garantir le meilleur niveau de fonctionnement
        et de qualité du Site grâce notamment aux statistiques de visites&nbsp;; </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>-Le consentement de l’Utilisateur lorsque celui-ci est requis. </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='margin-top:12.0pt;text-align:justify'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
        7.5pt;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt'><b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>d.<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
        dir=LTR></span><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.15pt'>La gestion des demandes de droits issus du
        RGPD et de la Loi Informatique et Libertés modifiée</span></b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'> </span></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;margin-top:0cm;text-align:justify'><b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:#C00000;
        letter-spacing:.15pt'>En savoir plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#C00000;letter-spacing:.15pt'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Détails du traitement</span></u></b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black;letter-spacing:.15pt'>&nbsp;: </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Ce traitement couvre l’ensemble des opérations nécessaires au suivi des
        demandes de droits adressées auprès de CED FRANCE (qualification de la demande,
        investigations, réalisation d’opérations techniques spécifiques etc.). Il ne
        concerne que les cas où CED FRANCE agit en qualité de responsable de traitement</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>Base légale</span></u></b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black;letter-spacing:.15pt'>&nbsp;: Obligation légale
        découlant du RGPD et de la Loi Informatique &amp; Libertés modifiée.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>5/ Quelles données
        personnelles collectons-nous vous concernant et pourquoi&nbsp;?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;
        text-align:justify;background:white'><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black'>Les données à caractère personnel collectées
        sur vous sont nécessaires </span><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:#333333'>au traitement de vos demandes. Le caractère
        obligatoire des informations demandées vous est signalé lors de la collecte par
        la présence d’un astérisque.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Vous pouvez consulter le détail des données à
        caractère personnel que nous sommes susceptibles d’avoir sur vous ci-après :</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>NB&nbsp;: </span></b><span style='font-size:
        10.0pt;font-family:"Arial",sans-serif'>le détail des informations fournies
        ci-après n’a pas vocation à être exhaustif et vise avant tout à informer les
        Utilisateurs sur les catégories de données que CED FRANCE est susceptible de
        traiter.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-left:0cm;text-align:justify;background:
        #D9E2F3'><b><u><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoListParagraph style='margin-left:0cm;text-align:justify;background:
        #D9E2F3'><b><u><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.15pt'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoListParagraph style='margin-left:0cm;text-align:justify;background:
        #D9E2F3'><b><u><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.15pt'>Pour la déclaration et le suivi de la gestion
        des sinistres et la lutte contre la fraude </span></u></b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>:</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>•Etat civil : nom, prénom, civilité&nbsp;;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>•Coordonnées : Adresse, courriel et numéros de téléphone&nbsp;;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>•Identifiants du Client VYV Conseil et du contrat souscrit auprès de VYV
        Conseil&nbsp;;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>•Dates de survenance et de déclaration du sinistre et numéro de
        sinistre&nbsp;;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;letter-spacing:
        .15pt'>•Pièces justificatives du sinistre : Photos de votre domicile, factures,
        etc&nbsp;;</span></p>
        
        <p class=MsoListParagraph style='margin-left:0cm;text-align:justify;background:
        #D9E2F3'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.15pt'>•Données indiquées dans les circonstances et
        nature des dommages&nbsp;;</span></p>
        
        <p class=MsoListParagraph style='margin-left:0cm;text-align:justify;background:
        #D9E2F3'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:black;letter-spacing:.15pt'>•Données fournies par l’Utilisateur via le
        Site</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'><span style='text-decoration:
         none'>&nbsp;</span></span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Pour le bon
        fonctionnement et l’amélioration permanente du Site</span></u></b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;:</span></u></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Les données
        collectées via les cookies et autres traceurs présents sur notre Site ; pour
        plus de détails, consultez notre Politique de gestion des cookies.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;background:lightgrey'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Pour la
        gestion de demandes de droits issus du RGPD et de la Loi Informatique et
        Libertés modifiée</span></u></b><u><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black'>&nbsp;:</span></u></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>-Nom,
        prénom&nbsp;;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>-Adresse courriel
        ; </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>-Le cas
        échéant, copie d’un justificatif d’identité. </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal><span style='font-size:10.0pt'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>6/ Quelles sont les garanties
        de sécurité sur les données à caractère personnel que nous appliquons&nbsp;?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Nous traitons vos données à caractère personnel
        de manière&nbsp;:</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>licite, loyale, transparente, </span><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>proportionnée,</span><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'> </span><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>pertinente,</span><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'> </span><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>dans le strict cadre
        des finalités poursuivies, annoncées et pour la durée nécessaire aux
        traitements mis en place,</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>de façon sécurisée.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Nous et nos éventuels sous-traitants prenons
        toutes les mesures techniques, juridiques et organisationnelles raisonnables, en
        application de la Loi informatique et Libertés modifiée et du Règlement
        européen sur la protection des données (RGPD)., afin de protéger vos données à
        caractère personnel</span><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>
        en empêchant qu’elles soient déformées, endommagées ou communiquées à des tiers
        non autorisés</span><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>.
        </span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>CED FRANCE prend les précautions utiles, au
        regard de la nature de vos données et des risques présentés par notre
        traitement, pour préserver la sécurité des données et, notamment, empêcher
        qu’elles soient déformées, endommagées, ou que des tiers non autorisés y aient
        accès (protection physique des locaux, procédé d’authentification de nos
        clients avec accès personnel et sécurisé via des identifiants et mots de passe
        confidentiels, journalisation des connexions…).</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>7/ Qui a accès à vos Données
        personnelles&nbsp;?&nbsp;</span></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;background:white'><b><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:#333333'>&nbsp;</span></b></p>
        
        <p class=MsoListParagraph style='margin-bottom:7.5pt;text-indent:-18.0pt;
        background:white'><span style='font-size:10.0pt;font-family:Symbol;color:#333333'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:#333333'>Le personnel habilité de CED FRANCE</span></b></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;background:
        white'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;color:#333333'>Vos
        données personnelles sont destinées aux personnes dûment et strictement
        habilitées à les traiter au sein de CED France en fonction de leurs
        attributions.</span></p>
        
        <p class=MsoListParagraph style='margin-bottom:7.5pt;text-indent:-18.0pt;
        background:white'><span style='font-size:10.0pt;font-family:Symbol;color:#333333'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:#333333'>Notre partenaire EUREXO pour l’expertise en
        assurance</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#242424'>Également, vos données
        personnelles peuvent être communiquées à des experts en assurance agissant
        comme responsables de traitement distincts notamment en vue du traitement d’une
        indemnisation lors d’un sinistre.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#242424'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-bottom:7.5pt;text-indent:-18.0pt;
        background:white'><span style='font-size:10.0pt;font-family:Symbol;color:#333333'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:#333333'>Prestataire chargé de la gestion, de l’hébergement
        du Site et du système d’information de CED FRANCE</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#242424'>Enfin, vos données personnelles
        sont hébergées par Microsoft Azure au sein de data centers situés en Union
        Européenne.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#242424'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-bottom:7.5pt;text-align:justify;
        text-indent:-18.0pt;background:white'><span style='font-size:10.0pt;font-family:
        Symbol;color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:#333333'>Le cas échéant, le personnel habilité de nos
        sous-traitants&nbsp;; </span></b></p>
        
        <p class=MsoListParagraph style='margin-bottom:7.5pt;text-align:justify;
        text-indent:-18.0pt;background:white'><span style='font-size:10.0pt;font-family:
        Symbol;color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:#333333'>Le cas échéant, les juridictions concernées,
        médiateurs, experts-comptables, commissaires aux comptes, avocats, huissiers,
        sociétés de recouvrement de créances, autorités de police ou de gendarmerie en
        cas de vol ou de réquisition judiciaire&nbsp;; </span></b></p>
        
        <p class=MsoListParagraph style='margin-bottom:7.5pt;text-align:justify;
        text-indent:-18.0pt;background:white'><span style='font-size:10.0pt;font-family:
        Symbol;color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:#333333'>Les tiers susceptibles de déposer des cookies
        sur vos terminaux lorsque vous y consentez</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#242424'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>8/ Transferts hors Union
        Européenne</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Par principe, CED FRANCE traite vos données au
        sein de l’Union européenne. </span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>9/ Combien de temps
        gardons-nous vos données à caractère personnel ?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>CED FRANCE s’engage à ce que les Données
        collectées soient conservées sous une forme permettant votre identification
        pendant une durée qui n’excède pas la durée nécessaire aux finalités pour
        lesquelles ces données sont collectées et traitées.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;background:
        white'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Afin
        de déterminer la durée de conservation de vos données personnelles, nous
        faisons application des critères suivants :</span></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;margin-left:21.3pt;text-align:
        justify;text-indent:-18.0pt;background:white'><span style='font-size:10.0pt;
        font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black'>Le temps nécessaire pour traiter votre demande </span></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;margin-left:21.3pt;text-align:
        justify;text-indent:-18.0pt;background:white'><span style='font-size:10.0pt;
        font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black'>La nécessité de conserver un historique de vos déclarations
        pour la bonne gestion de vos sinistres.</span></p>
        
        <p class=MsoNormal style='margin-bottom:7.5pt;text-align:justify;background:
        white'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Au-delà
        de ces durées, vos données personnelles peuvent également faire l’objet d’un
        archivage impliquant un accès encadré, limité et justifié, le temps nécessaire
        (i) au respect des obligations légales et réglementaires de CED, et/ou (ii)
        pour lui permettre de faire valoir un droit en justice, et ce avant d’être
        définitivement supprimées.&nbsp;&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#C00000'>En savoir plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>La
        déclaration et suivi de la gestion des sinistres </span></u></b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>:</span></u></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Les données
        sont conservées pendant le temps nécessaire à votre demande puis archivées à
        des fins probatoires conformément aux obligations légales et règlementaires
        auxquelles CED FRANCE est soumise. </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>La gestion
        de la lutte contre la fraude</span></u></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Les données
        sont conservées le temps nécessaire à la gestion de votre demande et</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>-6 mois
        pour une alerte non qualifiée ;</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>-5 ans à
        compter de la clôture du dossier de fraude ou jusqu’au terme de la procédure
        judiciaire.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Le bon
        fonctionnement et l’amélioration du Site</span></u></b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;:</span></u></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Les cookies
        et autres traceurs commerciaux peuvent être déposés sur le terminal de l’Utilisateur
        pour une durée maximale de treize (13) mois. Au-delà de ce délai, les données
        de fréquentation brutes associées à un identifiant sont soit supprimées soit
        anonymisées.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Les
        informations collectées par l'intermédiaire de cookies et traceurs sont
        conservées pour une durée de vingt-cinq (25) mois. Au-delà de ce délai, ces
        données sont supprimées ou anonymisées.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><b><u><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Pour la
        gestion de demandes de droits issus du RGPD et de la Loi Informatique et
        Libertés modifiée</span></u></b><u><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif;color:black'>&nbsp;</span></u><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>: </span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>Les données
        relatives à la gestion des demandes de droit sont conservées pour toute la
        durée nécessaire au traitement de la demande. Elles sont par la suite archivées
        pour la durée de prescription pénale applicable en archivage intermédiaire.</span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>En cas de
        communication de copie de pièce d’identité, elle peut être conservée pendant un
        (1) an après réception de la demande. <a name="_heading=h.2et92p0"></a></span></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>10/ Quels sont mes droits sur mes
        données à caractère personnel ?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:black'>Conformément à la Loi Informatique
        et Libertés et au RGPD, vous disposez des droits suivants</span><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'> </span><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>:</span></p>
        
        <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <ul style='margin-top:0cm' type=disc>
         <li class=MsoNormal style='color:#3C3C3B;text-align:justify;line-height:107%'><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>Droit d’accès&nbsp;</span><span style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article15"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>(</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article15"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#002060'>article 15 RGPD</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article15"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>)</span></a></span><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>, de rectification&nbsp;</span><span style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article16"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>(</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article16"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#002060'>article 16 RGPD</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article16"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>)</span></a></span><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>, de mise à jour&nbsp;;</span></li>
         <li class=MsoNormal style='color:#3C3C3B;text-align:justify;line-height:107%'><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>Droit à l’effacement de vos Données à caractère
             personnel&nbsp;</span><span style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article17"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>(</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article17"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#002060'>article 17 RGPD</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article17"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>)</span></a></span><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>,
             </span><span style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>lorsqu’elles sont inexactes, incomplètes, équivoques,
             périmées, ou dont la collecte, l'utilisation, la communication ou la
             conservation est interdite</span></li>
         <li class=MsoNormal style='color:#3C3C3B;text-align:justify;line-height:107%'><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>Droit de retirer à tout moment votre consentement&nbsp;(</span><span
             style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre2#Article7"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#002060'>article 7-3 RGPD</span></a></span><span style='font-size:
             10.0pt;line-height:107%;font-family:"Arial",sans-serif;color:black'>)</span></li>
         <li class=MsoNormal style='color:#3C3C3B;text-align:justify;line-height:107%'><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>Droit à la limitation du traitement de vos Données&nbsp;</span><span
             style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article18"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>(</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article18"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#002060'>article 18 RGPD</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article18"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>)</span></a></span></li>
         <li class=MsoNormal style='color:#3C3C3B;margin-bottom:8.0pt;text-align:justify;
             line-height:107%'><span style='font-size:10.0pt;line-height:107%;
             font-family:"Arial",sans-serif;color:black'>Droit à la portabilité des
             Données que vous nous avez fournies, lorsque vos données font l’objet de
             traitements automatisés fondés sur votre consentement ou sur un
             contrat&nbsp;</span><span style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article20"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>(</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article20"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#002060'>article 20 RGPD</span></a><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article20"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>)</span></a></span></li>
         <li class=MsoNormal style='color:#3C3C3B;margin-bottom:8.0pt;text-align:justify;
             line-height:107%'><span style='font-size:10.0pt;line-height:107%;
             font-family:"Arial",sans-serif;color:black'>Droit d’opposition au
             traitement des Données à caractère personnel (</span><span
             style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article21"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#0563C1'>article 21 du RGPD</span></a></span><span style='font-size:
             10.0pt;line-height:107%;font-family:"Arial",sans-serif;color:black'>)</span></li>
         <li class=MsoNormal style='color:#3C3C3B;margin-bottom:8.0pt;text-align:justify;
             line-height:107%'><span style='font-size:10.0pt;line-height:107%;
             font-family:"Arial",sans-serif;color:black'>Droit d’introduire une
             réclamation auprès de la CNIL (</span><span style='color:windowtext'><a
             href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre8"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#0563C1'>article 77 du RGPD</span></a></span><span style='font-size:
             10.0pt;line-height:107%;font-family:"Arial",sans-serif;color:black'>)</span></li>
         <li class=MsoNormal style='color:#3C3C3B;margin-bottom:8.0pt;text-align:justify;
             line-height:107%'><span style='font-size:10.0pt;line-height:107%;
             font-family:"Arial",sans-serif;color:black'>Droit de définir le sort de
             vos données après votre mort et de choisir que nous communiquions (ou non)
             vos données à un tiers que vous aurez préalablement désigné&nbsp;</span><span
             style='color:windowtext'><a
             href="https://www.cnil.fr/fr/ce-que-change-la-loi-pour-une-republique-numerique-pour-la-protection-des-donnees-personnelles#mortnumerique"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>(</span></a><a
             href="https://www.legifrance.gouv.fr/affichTexteArticle.do;jsessionid=B12811F09C5A904722C8DE37ABEA140D.tplgfr21s_2?idArticle=LEGIARTI000037813987&amp;cidTexte=LEGITEXT000006068624&amp;dateTexte=20191106"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:#0563C1'>article 85 LIL</span></a><a
             href="https://www.cnil.fr/fr/ce-que-change-la-loi-pour-une-republique-numerique-pour-la-protection-des-donnees-personnelles#mortnumerique"><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black;text-decoration:none'>)</span></a></span><span
             style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif;
             color:black'>. En cas de décès et à défaut d’instructions de votre part,
             nous nous engageons à détruire vos données, sauf si leur conservation
             s’avère nécessaire à des fins probatoires ou pour répondre à une
             obligation légale.</span></li>
        </ul>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Pour mieux connaître vos droits, vous pouvez
        également consulter le site de la Commission Nationale de l’Informatique et des
        Libertés, accessible à l’adresse suivante&nbsp;: </span><a href="http://cnil.fr"><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>http://cnil.fr</span></a><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>.</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>11/ Qui contacter à propos de
        mes données à caractère personnel&nbsp;?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Vous souhaitez exercer vos droits ou manifester
        une réclamation relativement à vos données à caractère personnel traitées par le
        Site&nbsp;: </span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt'><span
        style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;font-family:
        "Arial",sans-serif'>Vous pouvez nous contacter directement en consultant nos Mentions
        légales où figurent l’ensemble de nos coordonnées.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Les
        coordonnées du Délégué à la protection des données sont les suivantes&nbsp;: </span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Soit par courriel, à l’adresse
        suivante&nbsp;:</span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif'> dpo@eurexo-ced.fr</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Soit par courrier à l’adresse
        suivante&nbsp;: </span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif'>CED France, 2 rue Jacques Daguerre – 92500
        RUEIL MALMAISON</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Dans tous les cas, vous disposez du droit
        d’introduire une réclamation auprès de la CNIL.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>12/&nbsp;Données de connexion
        et cookies</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Un
        cookie est un fichier texte déposé et lu notamment lors de votre navigation sur
        un site Internet, quel que soit le type d’équipement terminal utilisé.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Nous
        vous renvoyons sur la page Politique de gestion des Cookies.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt'>13/ Mise à jour de notre
        politique de confidentialité</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-autospace:none'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#5F6062'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;text-autospace:none'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif'>Enfin, nous révisons
        régulièrement la présente politique de confidentialité suivant les différentes
        évolutions législatives et réglementaires et nous nous réservons le droit d’y
        apporter des modifications à tout moment pour tenir compte des changements de
        nos exigences commerciales et légales. À cet effet, l’Utilisateur pourra
        consulter la mise à jour directement sur le Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Version mise à jour le 3 avril 2023.</span></p>
        
        <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
        color:#1F497D'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal><span style='font-size:10.0pt'>&nbsp;</span></p>
        
        <p class=MsoNormal><span style='font-size:10.0pt'>&nbsp;</span></p>
        
    </div>

<!-- Mentions légales -->
<div *ngIf="pageName=='mentions'" class="page-content">
    <p class=MsoNoSpacing align=center style='text-align:center'><b><span
        style='font-family:"Arial",sans-serif;text-transform:uppercase'>Mentions
        légales</span></b></p>
        
        <p class=MsoNormal style='background:white'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='background:white'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoListParagraph style='text-indent:-18.0pt;background:white'><b><span
        style='font-family:"Arial",sans-serif'>1-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></b><span dir=LTR></span><b><span style='font-family:"Arial",sans-serif;
        color:black'>1- ÉDITEUR DU SITE</span></b><span style='font-family:"Arial",sans-serif;
        color:black'><br>
        <br>
        </span></p>
        
        <p class=MsoNormal style='background:white'><b><span style='font-family:"Arial",sans-serif;
        color:black'>CED FRANCE&nbsp;:</span></b></p>
        
        <p class=MsoNormal style='background:white'><span style='font-family:"Arial",sans-serif;
        color:black'>Société à responsabilité limitée au capital social de 676&nbsp;224,51
        €<br>
        Siège social&nbsp;: 2, rue Jacques Daguerre, 92500 RUEIL MALMAISON<br>
        SIREN : &nbsp;R.C.S NANTERRE <span class=AJvar><span style='font-style:normal'>418&nbsp;501
        524</span></span></span></p>
        
        <p class=MsoNormal style='background:white'><span style='font-family:"Arial",sans-serif;
        color:black'>Code APE&nbsp;: 6622 Z<br>
        Numéro de TVA intracommunautaire :<span style='background:white'> FR 22418501524</span></span></p>
        
        <p class=MsoNormal style='background:white'><span style='font-family:"Arial",sans-serif;
        color:black'>Directeur de la publication&nbsp;: Monsieur Robert de Waard</span></p>
        
        <p class=MsoNormal><span style='font-family:"Arial",sans-serif'>Téléphone&nbsp;:
        </span><span style='font-family:"Arial",sans-serif'>+33 1 55 47 12 82</span></p>
        
        <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
        margin-left:0cm;line-height:14.4pt'><span style='font-family:"Arial",sans-serif'>Courriel&nbsp;:
        </span><a href="mailto:robert.dewaard@ced-claimexperts.fr"><span
        style='font-family:"Arial",sans-serif;color:windowtext'>robert.dewaard@ced.group</span></a></p>
        
        <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
        margin-left:0cm;line-height:14.4pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
        margin-left:0cm;line-height:14.4pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
        6.0pt;margin-left:36.0pt;text-indent:-18.0pt;line-height:14.4pt'><b><span
        style='font-family:"Arial",sans-serif'>2-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></b><span dir=LTR></span><b><span style='font-family:"Arial",sans-serif'>HEBERGEMENT
        INTERNET&nbsp;</span></b><span style='font-family:"Arial",sans-serif;
        background:yellow'><br> 
        <br>
        </span></p>
        
        <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
        margin-left:0cm;line-height:14.4pt'><b><span style='font-family:"Arial",sans-serif'>MICROSOFT
        AZURE </span></b></p>
        
        <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
        margin-left:0cm;line-height:14.4pt'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal><span style='font-family:"Arial",sans-serif;color:black;
        background:white'>37 Quai du Président Roosevelt, 92130 Issy-les-Moulineaux</span></p>
        
         <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
              style='font-family:"Arial",sans-serif'>Téléphone&nbsp;: 01 57 32 42 18</span></p>
              
        <p class=MsoNormal style='background:white'><span style='font-family:"Arial",sans-serif;
        color:black'><br>
        <br>
        </span></p>
        
    </div>    

<!-- cookies -->
<div *ngIf="pageName=='cookies'" class="page-content">
    <div class=WordSection1>
    <div style='border:solid windowtext 1.0pt;padding:1.0pt 4.0pt 1.0pt 4.0pt'>

        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>Politique
        de gestion des Cookies</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal align=center style='text-align:center;border:none;
        padding:0cm'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        </div>
        
        <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Version du 03/04/2023</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>1/
        Préambule</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Cette
        Politique de gestion des cookies s’adresse aux Utilisateurs du Site Internet de
        CED FRANCE accessible à l’adresse suivante&nbsp;:<b><span style='color:black'> </span></b></span><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>www.</span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif'> sinistre-MRH-HM.cedcloud.com</span><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>.</span><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'> (<i>ci-après «&nbsp;<b>le Site</b>&nbsp;»).</i> Un
        Utilisateur se caractérise comme toute personne qui accède ou navigue sur le
        Site (<i>ci-après dénommés les «&nbsp;<b>Utilisateurs</b>&nbsp;» ou «&nbsp;<b>Vous</b>&nbsp;»).</i></span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>En
        exprimant votre consentement par le biais d’un acte positif clair, vous
        consentez à ce que les cookies et autres traceurs tels que décrits <a
        name="_Hlk61510294">ci-dessous </a>puissent être placés sur votre ordinateur
        fixe ou mobile, tablette, smartphone, ou tout autre équipement terminal,
        conformément à la présente Politique de gestion des cookies. Toutefois, vous
        pouvez à tout moment retirer votre consentement grâce à des solutions
        accessibles lors de votre navigation sur le Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>2/
        Qui collecte mes données</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNoSpacing><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>Le
        responsable de traitement de vos données collectées <i>via</i> le Site est&nbsp;:</span></p>
        
        <p class=MsoNoSpacing><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>CED
        France,<a name="_Hlk126248785"> Société à responsabilité limitée au capital
        social de 676&nbsp;224,51 €<br>
        Siège social&nbsp;: 2, rue Jacques Daguerre, 92500 NANTERRE<br>
        SIREN : &nbsp;R.C.S NANTERRE <span class=AJvar><span style='font-style:normal'>418&nbsp;501
        524</span></span></a></span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Certains
        fournisseurs de cookies utilisés par CED FRANCE peuvent être amenés à
        réutiliser les informations collectées pour leur propre compte. Dans cette
        hypothèse, l’Utilisateur est invité à consulter la Politique de confidentialité
        des fournisseurs de cookies pour obtenir des informations complémentaires sur
        la manière dont ces derniers réalisent leurs opérations de traitement.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif;color:#595959'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#C00000'>En savoir plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#C00000'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;background:#D9E2F3'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Le <b>responsable du traitement</b> est, au sens de la loi
        Informatique et Libertés n°78-17 du 6 janvier 1978 et du Règlement (UE)
        n°2016/679 du Parlement européen et du Conseil du 27 avril 2016 (ci-après
        «&nbsp;RGPD&nbsp;»), la personne qui détermine les finalités et les moyens du
        traitement. Lorsque plusieurs responsables du traitement déterminent les
        finalités et les moyens du traitement, ils sont qualifiés de responsables
        conjoints du traitement.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;background:#D9E2F3'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;background:#D9E2F3'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Le <b>sous-traitant</b> est une personne traitant des données à
        caractère personnel pour le compte du responsable du traitement, il agit sous
        l’autorité du responsable du traitement et sur instruction de celui-ci.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#4472C4'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>3/
        Qu’est-ce qu’un «&nbsp;Cookie&nbsp;»&nbsp;?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Un
        cookie est un fichier texte déposé et lu notamment lors de votre navigation sur
        un site Internet, quel que soit le type d’équipement terminal utilisé.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#595959'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#C00000'>En savoir plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;background:#D9E2F3'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Le <b>cookie</b> permet l’enregistrement de certaines informations
        sur votre navigation et votre comportement en ligne, nous permettant ainsi de
        faciliter et d’améliorer votre expérience en qualité d’internaute.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;background:#D9E2F3'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;background:#D9E2F3'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Le dépôt de ces fichiers</span></b><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif;color:black'> sur votre
        terminal nécessite le recueil préalable de votre consentement dès lors qu’ils
        ne sont pas strictement nécessaires au bon fonctionnement du Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#4472C4'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>4/
        A quoi servent les Cookies&nbsp;?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Les
        cookies utilisés sur le Site servent à vous différencier pour améliorer votre
        navigation ainsi que les services que nous vous proposons. CED FRANCE est amené
        à déposer sur votre navigateur&nbsp;d<b>es cookies fonctionnels et techniques</b>
        qui nous permettent de vous authentifier, de vous identifier, d’accélérer votre
        navigation sur notre Site et de faciliter l’accès à ses différentes
        fonctionnalités. Ils peuvent également permettre l’enregistrement de vos
        préférences, la sauvegarde de vos choix en matière de cookies, ou la mesure des
        différents usages du Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>5/
        Quels sont les Cookies utilisés&nbsp;?</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>CED FRANCE privilégie la transparence dans le
        traitement de vos données. </span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <h2><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>5.1/ Les
        cookies propriétaires</span></h2>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-bottom:8.0pt;margin-top:0cm;text-align:
        justify;text-indent:-18.0pt;line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Les cookies fonctionnels et techniques</span></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'> sont
        utilisés pour rendre possible et améliorer votre navigation sur le Site. Ils
        permettent l’envoi d’informations à notre Site et à votre navigateur&nbsp;: les
        préférences d’affichage de votre terminal (langue, résolution), la sauvegarde
        de vos choix en matière de cookies etc&nbsp;:</span></p>
        
        <p class=MsoListParagraph style='text-align:justify'><span style='font-size:
        10.0pt;font-family:"Arial",sans-serif;color:#595959'>&nbsp;</span></p>
        
        <p class=xxmsonormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Liste des traceurs techniques&nbsp;:</span></p>
        
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='border-collapse:collapse'>
         <tr style='height:30.45pt'>
          <td width=198 style='width:148.6pt;border:solid white 3.0pt;background:black;
          padding:0cm 5.4pt 0cm 5.4pt;height:30.45pt'>
          <p class=xxmsolistparagraph align=center style='margin-left:0cm;text-align:
          center'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          color:white'>Nom du traceur</span></b></p>
          </td>
          <td width=306 style='width:229.2pt;border:solid white 3.0pt;border-left:none;
          background:black;padding:0cm 5.4pt 0cm 5.4pt;height:30.45pt'>
          <p class=xxmsolistparagraph align=center style='margin-left:0cm;text-align:
          center'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          color:white'>Finalité du traceur</span></b></p>
          </td>
          <td width=100 style='width:75.3pt;border:solid white 3.0pt;border-left:none;
          background:black;padding:0cm 5.4pt 0cm 5.4pt;height:30.45pt'>
          <p class=xxmsolistparagraph align=center style='margin-left:0cm;text-align:
          center'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          color:white'>Durée</span></b></p>
          </td>
         </tr>
         <tr>
          <td width=198 style='width:148.6pt;border:solid white 3.0pt;border-top:none;
          background:#F2F2F2;padding:0cm 5.4pt 0cm 5.4pt'>
          <p class=xxmsolistparagraph align=center style='margin-left:0cm;text-align:
          center'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          color:black'>ARRAffinity / ARRAffinitySameSite</span></p>
          </td>
          <td width=306 style='width:229.2pt;border-top:none;border-left:none;
          border-bottom:solid white 3.0pt;border-right:solid white 3.0pt;background:
          #F2F2F2;padding:0cm 5.4pt 0cm 5.4pt'>
          <p class=xxmsolistparagraph style='margin-left:0cm'><span style='font-size:
          10.0pt;font-family:"Arial",sans-serif;color:black'>Ce cookie est défini par
          les sites Web exécutés sur la plate-forme cloud Windows Azure. Il est utilisé
          pour l'équilibrage de charge afin de s'assurer que les demandes de page des
          visiteurs sont acheminées vers le même serveur dans n'importe quelle session
          de navigation</span></p>
          </td>
          <td width=100 style='width:75.3pt;border-top:none;border-left:none;
          border-bottom:solid white 3.0pt;border-right:solid white 3.0pt;background:
          #F2F2F2;padding:0cm 5.4pt 0cm 5.4pt'>
          <p class=xxmsolistparagraph align=center style='margin-left:0cm;text-align:
          center'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          color:black'>Session</span></p>
          </td>
         </tr>
         <tr style='height:26.05pt'>
          <td width=198 style='width:148.6pt;border:solid white 3.0pt;border-top:none;
          background:#F2F2F2;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt'>
          <p class=xxmsolistparagraph align=center style='margin-left:0cm;text-align:
          center'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          color:black'>Session Storage</span></p>
          </td>
          <td width=306 style='width:229.2pt;border-top:none;border-left:none;
          border-bottom:solid white 3.0pt;border-right:solid white 3.0pt;background:
          #F2F2F2;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt'>
          <p class=xxmsolistparagraph style='margin-left:0cm'><span style='font-size:
          10.0pt;font-family:"Arial",sans-serif;color:black'>Authentification</span></p>
          </td>
          <td width=100 style='width:75.3pt;border-top:none;border-left:none;
          border-bottom:solid white 3.0pt;border-right:solid white 3.0pt;background:
          #F2F2F2;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt'>
          <p class=xxmsolistparagraph align=center style='margin-left:0cm;text-align:
          center'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          color:black'>Session</span></p>
          </td>
         </tr>
        </table>
        
        <p class=MsoNormal style='line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='margin-bottom:8.0pt;margin-top:0cm;text-align:
        justify;text-indent:-18.0pt;line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Il n’existe pas à ce jour de cookie </span></b><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>de
        mesure d’audience</span></b><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif'> <b>du Site.</b></span></p>
        
        <p class=MsoListParagraph style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#595959'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>6/
        Comment gérer les cookies&nbsp;?</span></p>
        
        <p style='margin:0cm;text-align:justify;line-height:115%;background:white'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p style='margin:0cm;text-align:justify;line-height:115%;background:white'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Vous avez la possibilité de maîtriser l’installation des cookies
        sur le Site en configurant, à tout moment, votre logiciel de navigation afin
        de/d’&nbsp;: </span></p>
        
        <p style='margin:0cm;text-align:justify;line-height:115%;background:white'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Accepter ou refuser les cookies se
        trouvant sur le Site&nbsp;;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Refuser systématiquement tous les
        cookies&nbsp;;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Donner votre consentement sur chacun des
        cookies que vous rencontrez lors de votre navigation sur le Site.</span></p>
        
        <p style='margin:0cm;text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p style='margin:0cm;text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Vous
        pouvez exprimer vos choix, gérer, bloquer ou autoriser les cookies directement
        en modifiant les paramètres de vos logiciels de navigation. Vous trouverez
        ci-après les options de paramétrage des principaux logiciels de
        navigation&nbsp;: </span></p>
        
        <ul type=disc>
         <li class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
             font-family:"Arial",sans-serif'>Avec <b>Microsoft Edge</b>™ : menu Outils
             &#9658; Paramètres &#9658; Onglet Confidentialité &#9658; choisissez les
             options souhaitées ;</span></li>
         <li class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
             font-family:"Arial",sans-serif'>Avec <b>Firefox</b>™ : menu Outils &#9658;
             Paramètres &#9658; Onglet Vie privée &#9658; choisissez les options
             souhaitées ;</span></li>
         <li class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
             font-family:"Arial",sans-serif'>Avec <b>Chrome</b>™ : menu de
             configuration (logo paramètres) &#9658; Paramètres &#9658; Onglet
             Confidentialité &#9658; Choisissez les options souhaitées ;</span></li>
         <li class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
             font-family:"Arial",sans-serif'>Avec <b>Safari</b>™ : Safari (logo
             paramètres) &#9658; Préférences &#9658; Sécurité &#9658; Afficher les
             cookies &#9658; Choisissez les options souhaitées ;</span></li>
         <li class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
             font-family:"Arial",sans-serif'>Avec <b>Opera</b>™ : menu Outils &#9658;
             Préférences &#9658; Onglet Avancé &#9658; Rubrique Cookies &#9658; Gérer
             les cookies &#9658; Choisissez les options souhaitées.</span></li>
        </ul>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#C00000'>En savoir plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;background:#D9E2F3'><span
        style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black'>En
        choisissant de bloquer nos cookies, votre navigation sur le Site et/ou
        l’utilisation de certaines fonctionnalités pourraient être altérées. Ainsi, si
        vous choisissez de supprimer tous les cookies, votre expérience en ligne sera
        potentiellement affectée et vos préférences de navigation seront effacées</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>7/
        Adresse IP &amp; autres traceurs</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Outre les Cookies détaillés aux articles 4 et 5
        de la présente Politique, CED FRANCE est également amené à traiter certaines
        informations techniques de l’Utilisateur afin de s’assurer que celui-ci est en
        mesure de consulter le Site dans des conditions optimales d’affichage, et
        d’identifier tout fonctionnement anormal du Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Peuvent être ainsi collectées par CED FRANCE
        les informations suivantes&nbsp;: votre adresse IP, l’identifiant de votre
        terminal, les métadonnées de votre ordinateur (date heure de l’utilisation,
        configuration, préférences linguistiques…) ou encore la zone géographique de
        votre connexion.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>En aucun cas, CED FRANCE n’utilise d’autres
        traceurs, des techniques de calcul d’empreinte de type «&nbsp;<i>fingerprinting</i>&nbsp;»
        ou des identifiants générés par des logiciels tiers pour effectuer des analyses
        du comportement des Utilisateurs</span><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#C00000'>En savoir plus</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#C00000'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%;background:#D9E2F3'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:black'>Les métadonnées de votre ordinateur comprennent les informations
        traitées dans un réseau de communications électroniques aux fins de la
        transmission, la distribution ou l'échange de contenu de communications
        électroniques produites lorsque vous naviguez sur Internet.</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>8/
        Consentement et bases légales</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>CED
        FRANCE peut installer des cookies dits «&nbsp;techniques&nbsp;» qui permettent
        d’accéder, d’inscrire et de consulter des informations stockées sur votre
        terminal. Le dépôt de ces cookies n’implique pas le recueil préalable de votre
        consentement mais repose sur la poursuite de l’intérêt légitime de CED FRANCE
        d’assurer le bon fonctionnement du Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Il
        s’agit&nbsp;:</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>De cookies ayant pour finalité de
        permettre ou faciliter la transmission de la communication par voie
        électronique&nbsp;; </span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>De cookies strictement nécessaires à la
        fourniture d’un service de communication en ligne du Site&nbsp;; et</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>De cookies destinés à conserver votre
        choix quant au dépôt de cookies.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
        color:#595959'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Dans
        tous les cas, vous êtes informés des finalités pour lesquelles vos données sont
        collectées par nos soins via les différents formulaires de collecte de données
        en ligne ou via notre <u>Politique de confidentialité</u><i>.</i></span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>9/
        Vos droits</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Conformément
        à la Loi Informatique et Libertés et au RGPD, vous disposez&nbsp;des droits
        suivants&nbsp;:</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Droit d’accéder</span></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'> (</span><a
        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article15"><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>article
        15 RGPD</span></a><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'>), de <b>rectifier</b> (</span><a
        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article16"><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>article
        16 RGPD</span></a><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'>), de <b>mettre à jour</b>, de <b>compléter</b> vos
        données&nbsp;à caractère personnel ; </span></p>
        
        <p class=MsoListParagraph style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Droit de verrouiller</span></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'> ou <b>d’effacer</b>
        vos données (</span><a
        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article17"><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>article
        17 RGPD</span></a><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'>), lorsqu’elles sont inexactes, incomplètes, équivoques,
        périmées, ou dont la collecte, l'utilisation, la communication ou la
        conservation est interdite&nbsp;; </span></p>
        
        <p class=MsoListParagraph style='line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Droit de retirer</span></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'> à
        tout moment votre <b>consentement</b> (</span><a
        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article13"><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>article
        13-2c RGPD</span></a><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'>)&nbsp;; </span></p>
        
        <p class=MsoListParagraph style='line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Droit de limiter le traitement</span></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'> de
        vos données (</span><a
        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article18"><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>article
        18 RGPD</span></a><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'>)&nbsp;; </span></p>
        
        <p class=MsoNormal style='line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Droit à la portabilité des données que
        vous nous avez fournies,</span></b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'> lorsque vos données font l’objet de
        traitements automatisés fondés sur votre consentement ou sur un contrat (</span><a
        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article20"><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>article
        20 RGPD</span></a><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'>)&nbsp;; </span></p>
        
        <p class=MsoListParagraph style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Droit de vous opposer au traitement</span></b><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'> de
        vos données (</span><a
        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article21"><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>article
        21 RGPD</span></a><span style='font-size:10.0pt;line-height:115%;font-family:
        "Arial",sans-serif'>)&nbsp;; </span></p>
        
        <p class=MsoNormal style='line-height:115%'><span style='font-size:10.0pt;
        line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><b><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Droit de définir le sort de vos données
        après votre mort</span></b><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif'> et de choisir que nous communiquions (ou non)
        vos données à un tiers que vous aurez préalablement désigné (<u>article 85 de
        la Loi Informatique et Liberté</u>). </span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>En cas
        de décès et à défaut d’instructions de votre part, nous nous engageons à
        détruire vos données, sauf lorsque leur conservation s’avère nécessaire à des
        fins probatoires ou pour répondre à une obligation légale.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Vous
        pouvez exercer vos droits en utilisant l’un des moyens suivants&nbsp;: </span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Soit par courriel, à l’adresse
        suivante&nbsp;:</span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif'> dpo@eurexo-ced.fr</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;text-indent:-18.0pt;
        line-height:115%'><span style='font-size:10.0pt;line-height:115%;font-family:
        Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span dir=LTR></span><span style='font-size:10.0pt;line-height:
        115%;font-family:"Arial",sans-serif'>Soit par courrier à l’adresse
        suivante&nbsp;: </span><span style='font-size:10.0pt;line-height:115%;
        font-family:"Arial",sans-serif'>CED France, 2 rue Jacques Daguerre – 92500
        RUEIL MALMAISON</span></p>
        
        <p class=MsoListParagraph style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Enfin,
        vous pouvez également introduire une réclamation auprès des autorités de
        contrôle et notamment de la CNIL (<u>article 77 du RGPD</u>).</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>10/
        Durées de conservation</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Les informations stockées dans votre terminal
        (ex : cookies) ou tout autre élément utilisé pour vous identifier à des fins de
        statistiques d’audience ne sont pas conservés au-delà d’une durée de treize
        (13) mois. Cette durée n’est pas prorogée automatiquement lors de vos nouvelles
        visites sur notre Site.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Les informations techniques nécessaires à votre
        navigation visée à l’article 7 de la présente Politique sont conservées pour
        une durée de six (6) mois.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Toutefois, les informations collectées par
        l’intermédiaire des traceurs peuvent être conservées pour une durée maximale de
        vingt-cinq (25) mois.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>Au-delà de ces délais, vos données sont soit
        supprimées soit anonymisées, rendant ainsi impossible votre identification et
        ce de manière irréversible.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
        font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoTitle><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>11/
        Mise à jour de la politique</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>Nous
        pouvons mettre à jour cette Politique de gestion des cookies afin de refléter,
        par exemple, des modifications relatives aux cookies que nous utilisons ou pour
        d’autres raisons opérationnelles, légales ou réglementaires. Nous vous invitons
        donc à consulter régulièrement cette Politique afin de rester informé de
        l’utilisation des cookies et des technologies associées.</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>
        
        <p class=MsoNormal style='text-align:justify;line-height:115%'><span
        style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'>La
        date présente en haut de cette Politique est la date de dernière mise à jour.</span></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        <p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>
        
        </div>
        
</div>
</div>    
<div>
    <button kendoButton class="trans_btn reg-font" type="button" (click)="close()">{{ 'navbar.close' | translate }}</button>
 </div>
