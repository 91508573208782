import { Component, OnInit } from '@angular/core';
import { ICONS } from 'src/app/shared/enum/icon';
import { SinistreSubjectService } from '../../sinistre-declaration/sinistre-subject.service';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  icons = ICONS;
  constructor(private sinistreSubjectService: SinistreSubjectService) { }

  ngOnInit(): void {
    this.sinistreSubjectService.setPageIsDeclaration(null);
  }

}
