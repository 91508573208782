import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class GenericService<T>{

    public url: string; 

    protected constructor(protected _http: HttpClient, private _url: string) {
        this.url = environment.BASE_URL + _url; 
    }

    getAll(): Observable<T[]> { 
        return this._http.get(this.url, this.prepareHeader()).pipe(map(res => res as T[]))
    }

    getOneById(id): Observable<T> {
        return this._http.get(this.url + id, this.prepareHeader()).pipe(map(res => res as T))
    }

    save(body): Observable<any> {
        return this._http.post(this.url, body, this.prepareHeader()).pipe(map(res => res as any));
    }

    update(body): Observable<any> {
        return this._http.put(this.url, body, this.prepareHeader()).pipe(map(res => res as any));
    }

    delete(id): Observable<any> {
        return this._http.delete(this.url + id, this.prepareHeader()).pipe(map(res => res as any));
    }

    protected prepareHeader(): Object {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });
        return { headers: headers };
    }
}