<div class="card" (window:resize)="onResize($event.target.innerWidth)">
  <div class="loading-style" *ngIf="isLoading"> 
    <img src="/assets/img/loading.gif" />
  </div>
  <div class="card-body page-container">
    <span class="title">{{ 'confirmation_sinistre.confirm_declaration' | translate }}</span>
    <hr>
    <div [ngClass]=" isMobile?'page-content-mobile': 'page-content' ">
      <!-- Vos informations -->
      <div class="row vyv-card">
        <div class="col-md-12">
          <div class="row">
            <div class="col-12">
              <span class="vyv-card-title">
                {{ 'confirmation_sinistre.vos_informations' | translate }}
              </span>
              <div class="info-msg">
                {{ 'confirmation_sinistre.vos_informations_msg' | translate }} 
                <span class="phone">{{ claimPhoneNumber}}</span> 
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 sinistre-field">
              <label class="label">{{ 'confirmation_sinistre.nom' | translate }}</label>
              <div readonly class="w-100 read-only-field">{{userInformation?.beneficName}}</div>
            </div>
            <div class="col-md-6 sinistre-field">
              <label class="label">{{ 'confirmation_sinistre.prenom' | translate }}</label>
              <div readonly class="w-100 read-only-field">{{userInformation?.beneficFirstname}}</div>
            </div>
            <div class="col-md-6 sinistre-field">
              <label class="label">{{ 'confirmation_sinistre.numero_contract' | translate }}</label>
              <div readonly class="w-100 read-only-field">{{userInformation?.contractId}}</div>
            </div>
            <div class="col-md-6 sinistre-field">
              <label class="label">{{ 'confirmation_sinistre.reference_client' | translate }}</label>
              <div readonly class="w-100 read-only-field">{{userInformation?.clientId}}</div>
            </div>
            <div class="col-md-6 sinistre-field">
              <label class="label">{{ 'confirmation_sinistre.adresse_assure' | translate }}</label>
              <div readonly class="w-100 read-only-field">{{userInformation?.insuredAddress}}</div>
            </div>
            <div class="col-md-6">
            </div>
            <div class="col-md-6 sinistre-field">
              <label class="label">{{ 'confirmation_sinistre.numero_telephone' | translate }}</label>
              <div readonly class="w-100 read-only-field">{{ numberWithSpace(userInformation?.beneficPhone)}}</div>
            </div>
        
            <div class="col-md-6 sinistre-field">
              <label class="label">{{ 'confirmation_sinistre.adresse_mail' | translate }}</label>
              <div readonly class="w-100 read-only-field">{{userInformation?.beneficMail}}</div>
          </div>

        </div>
      </div>
    </div>

    <!-- Pré-déclaration -->
    <div class="row vyv-card">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12 vyv-card-title d-flex justify-content-between">
            <span> {{ 'confirmation_sinistre.pre-declaration' | translate }}</span>
            <span  class="modifier" (click)="goToStep(0)">{{ 'confirmation_sinistre.modifier' | translate }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-6 sinistre-field">
            <label class="label">{{ 'confirmation_sinistre.survenance_sinistre' | translate }}</label>
            <div readonly class="w-100 read-only-field">{{currentSinistre?.cpOccurenceDate | date: 'dd/MM/yyyy':'':'fr' }}</div>
          </div>
          <div class="col-md-4 col-xs-6 sinistre-field">
            <label class="label">{{ 'confirmation_sinistre.nature_sinistre' | translate }}</label>
            <div class="nature-field">
            <img  [src]="natureName(currentSinistre?.cpClaimNaturesList[0].cnCode).icon" width="18" class="icon mr-2"/>
            <span class="nature-text" >{{natureName(currentSinistre?.cpClaimNaturesList[0].cnCode).text}}</span>
            </div>
          </div>

          <div class="col-md-4 col-xs-6 sinistre-field">
            <label class="label">{{ 'confirmation_sinistre.blesse' | translate }}</label>
            <div readonly class="w-100 read-only-field">{{isInjury}}</div>
          </div>

        </div>
        <div class="col-12">
          <label class="label"> {{ 'pre_declaration.nature_dommages' | translate }}</label>
          <div readonly class="w-100 dommage-field">{{currentSinistre?.cpCircumstance}}</div>
          </div>
          <div class="col-12 sinistre-field">
            <label class="label">{{ 'confirmation_sinistre.contact_message' | translate }}</label>
            <div class="read-only-field"> {{ numberWithSpace(currentSinistre?.cpContact || userInformation?.beneficPhone)}} </div>
          </div>
    </div>
  </div>

    <!-- List Des Documents -->
    <div class="row vyv-card">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12 vyv-card-title d-flex justify-content-between">
            <span>
             {{ 'confirmation_sinistre.document_list' | translate }}
            </span>
            <span class="modifier reg-font" (click)="goToStep(1)">{{ 'confirmation_sinistre.modifier' | translate }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div *ngFor="let doc of documents ;trackBy: trackByItem" class="document">
              <img [src]="icons.doc_gray" alt="Error" class="doc-icon"  /> <span class="document-title" >  {{doc.cpdFilename || doc}} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div class="row">
      <div class="col-12 d-flex justify-content-between mt-2 flex-wrap-reverse">
          <button [ngClass]="{'w-100':screenWidth<420}" type="button" class="btn btn-outline-primary cancel-button" (click)="goToStep(1)"> {{ 'shared.retour' | translate }}</button>
          <button [ngClass]="{'w-100':screenWidth<420}" type="button" class="btn btn-primary next-button" (click)="updateClaim()"> {{ 'shared.envoyer' | translate }} </button>
      </div>
  </div>
</div>