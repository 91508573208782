import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DialogCloseResult, DialogService } from "@progress/kendo-angular-dialog";
import { take } from 'rxjs/operators';
import { ClaimPostDocumentService } from 'src/app/shared/service/claim-post-document.service';
import { DocumentService } from 'src/app/shared/service/document-service';
import { AddDocumentComponent } from '../add-document/add-document.component';
import { DocumentModel } from 'src/app/shared/models/document.model';
import { SinistreDeclarationComponent } from '../sinistre-declaration.component';
import { SinistreSubjectService } from '../sinistre-subject.service';
import { SinistreDocumentModel } from 'src/app/shared/models/sinistre-document.model';
import { ICONS } from 'src/app/shared/enum/icon';
@Component({
  selector: 'app-transmission-documents',
  templateUrl: './transmission-documents.component.html',
  styleUrls: ['./transmission-documents.component.scss']
})
export class TransmissionDocumentsComponent implements OnInit {
  public opened = false;
  isBigScreen=true;
  isMobile=false;
  uploadingFile=false;
  selectedDoc = null;
  currentSinistre=null;
  icons = ICONS;
  @Input() formSinistre;
  @Output() stepTochange = new EventEmitter<number>();

  public close(status: string): void {
    if(status) this.removeDoc(this.selectedDoc);
    this.opened = false;
  }

  public open(id): void {
    this.opened = true;
    this.selectedDoc = id;
  }
  public documentsList: Array<SinistreDocumentModel>= [];
  
  constructor(private dialogService: DialogService,private documentService:DocumentService, private claimPostDocumentService:ClaimPostDocumentService, private sinistreDeclarationComponent: SinistreDeclarationComponent, private sinistreSubjectService: SinistreSubjectService) { }

  ngOnInit(): void {
    this.getSinistre();
    this.onResize(window.innerWidth)
  }

  getSinistre(){
    this.sinistreSubjectService.newSinistre.subscribe(result=>{
      this.documentsList= result.cpDocumentList;
      this.currentSinistre = result;
    })
  }
  openAddDocumentPopUp() {
    const dialogRef = this.dialogService.open({
        content: AddDocumentComponent ,
    });
      
    dialogRef.result.subscribe((result: any) => {
      if (!(result instanceof DialogCloseResult)) {
        this.documentsList.push({cpdFilename:result.Pages[0].fileName, cpdId:null});
        this.currentSinistre.cpDocumentList = this.documentsList;
        this.sinistreSubjectService.changeSinistreValue(this.currentSinistre);
        this.documentService.save(result).pipe(take(1)).subscribe(id=>{
          if(id){
            this.documentsList = this.documentsList.filter(x=>x.cpdFilename !==result.Pages[0].fileName);
            this.documentsList.push({cpdFilename:result.Pages[0].fileName, cpdId:id.result.id});
            this.currentSinistre.cpDocumentList = this.documentsList;
            this.sinistreSubjectService.changeSinistreValue(this.currentSinistre);
          }  
          let document =new DocumentModel();
          document.CpdId=id.result.id;
          document.CpdFilename=result.Pages[0].fileName;
          document.CpdClaimPostId=this.sinistreDeclarationComponent.currentClaimData.cpId;
          document.CpdCreatedDate=new Date();
          document.CpdCreatedBy="";
          this.uploadingFile = true
          this.claimPostDocumentService.save(document).pipe(take(1)).subscribe(result=>{
            this.uploadingFile =false;
          })
            
          }, error=> {
            this.documentsList.pop();
            this.currentSinistre.cpDocumentList = this.documentsList;
          })
      }
    })
  }
  onResize(event) {
      this.isBigScreen = event>=1647;
      this.isMobile = event<1200;
}
  removeDoc(id){
    this.claimPostDocumentService.delete(id).pipe(take(1)).subscribe(result=>{
      this.documentsList = this.documentsList.filter(x=> x.cpdId !==id);
      this.currentSinistre.cpDocumentList = this.documentsList;
      this.sinistreSubjectService.changeSinistreValue(this.currentSinistre);
      this.selectedDoc= null;
    })
  }
  back(){
      this.stepTochange.emit(0);
  }
  next(){
      this.stepTochange.emit(2);
  }
  isDisabledButton(){
   return  !!this.documentsList.find(x=>x.cpdId==null);
  }  
  

}
