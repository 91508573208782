import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ICONS } from 'src/app/shared/enum/icon';
import { SinistreNatureModel } from 'src/app/shared/models/sinistre-nature.model';
import { SinistreModel } from 'src/app/shared/models/sinistre.model';
import { ClaimPostService } from 'src/app/shared/service/claim-post-service';
import { SinistreSubjectService } from '../sinistre-subject.service';

@Component({
  selector: 'app-pre-declaration',
  templateUrl: './pre-declaration.component.html',
  styleUrls: ['./pre-declaration.component.scss']
})
export class TeledeclarationComponent implements OnInit , OnDestroy{
  newSinistre: SinistreModel = new SinistreModel();
  isLoading=false;
  submitted = false;
  isMobile = false;
  public today: Date = new Date();
  isInjury= false;
  sinistre_types: SinistreNatureModel[]=[];
  icons= ICONS;
  @Input() userInformation;
  @Input() formSinistre;
  public value: Date = null;
  @Output() stepTochange = new EventEmitter<number>();
  selectedSinistre = new SinistreNatureModel();
  @Input()  claimPhoneNumber;
  constructor(private translate: TranslateService, private sinistreSubjectService:SinistreSubjectService, private claimPostService: ClaimPostService) { }

  ngOnDestroy() : void{
  }
  ngOnInit(): void {
    this.onResize(window.innerWidth)
    this.getSinistresNatures()
    this.getSinistre(); 
  }
  setCurrentSinistre(){
  if(!!this.newSinistre){
    if(this.newSinistre.cpOccurenceDate) this.formSinistre.controls['date'].setValue(new Date(this.newSinistre.cpOccurenceDate));
    if(this.newSinistre.cpIsBodilyInjury!==null) this.formSinistre.controls['blesseur'].setValue(this.newSinistre.cpIsBodilyInjury ? 'true':'false');
    if(!!this.newSinistre.cpClaimNaturesList && this.newSinistre.cpClaimNaturesList.length!==0) this.formSinistre.controls['nature'].setValue(this.newSinistre.cpClaimNaturesList[0].cnId);
    this.formSinistre.controls['circonstances'].setValue(this.newSinistre.cpCircumstance);
    this.formSinistre.controls['contact'].setValue(this.newSinistre.cpCallback);
    this.selectedSinistre =(this.newSinistre.cpClaimNaturesList)? this.newSinistre.cpClaimNaturesList[0]:null;
    this.formSinistre.controls['date'].setValidators(Validators.required);
  } 
  }
  goToStep(index){
    this.submitted = true;
    if(this.formSinistre.valid){
      this.saveUpdate(index)
    }
  }
  getSinistre(){
    this.sinistreSubjectService.newSinistre.subscribe(result=>{
      this.newSinistre= result;
      this.setCurrentSinistre()
    })
  }
  getSinistresNatures(){
    this.sinistreSubjectService.listSinistres.subscribe(result=>{
      this.sinistre_types= result;
      if(this.newSinistre && !!this.newSinistre.cpClaimNaturesList && this.newSinistre.cpClaimNaturesList.length!==0) this.formSinistre.controls['nature'].setValue(this.newSinistre.cpClaimNaturesList[0].cnId);
    })
  }
  onResize(event) {
     this.isMobile = event<1200;
    }
  saveUpdate(index){ 
    if(!!this.newSinistre && this.formSinistre.controls['circonstances'].value.trim().length!=0){
      this.isLoading = true;
      this.newSinistre.cpIsBodilyInjury = this.formSinistre.controls['blesseur'].value ==='true' ? true:false;
      this.newSinistre.cpOccurenceDate = new Date(this.formSinistre.controls['date'].value).toLocaleDateString('fr-FR').split('/').reverse().join('-');
      this.newSinistre.cpClaimNaturesList = [this.sinistre_types.find(x=>x.cnId === this.formSinistre.controls['nature'].value)] ;
      this.newSinistre.cpCircumstance = this.formSinistre.controls['circonstances'].value;
      this.newSinistre.cpContact = !!this.formSinistre.controls['contact'].value ? this.formSinistre.controls['contact'].value.replace(/\s/g, ''):""; 
      this.newSinistre.cpCallback = !!this.formSinistre.controls['contact'].value ?this.formSinistre.controls['contact'].value.replace(/\s/g, ''):""; 
      this.sinistreSubjectService.changeSinistreValue(this.newSinistre);
      if(!this.newSinistre.cpDocumentList) this.newSinistre.cpDocumentList = [];
      this.claimPostService.update(this.newSinistre).subscribe(result=>{
        this.isLoading = false;
        this.stepTochange.emit(index);
      }, error =>{
        this.isLoading = false;
      })
    }
  }
  deleteNotNumber($event){
    let spacePlacement = this.formSinistre.controls['contact'].value.match(/.{1,1}/g) || [];
    let newNumber = ""
    spacePlacement.forEach(element => {
      if(["0","1","2","3","4","5","6","7","8","9"," ","+"].includes(element)){
        newNumber = newNumber + element
      }
    });
       this.formSinistre.controls['contact'].setValue(newNumber);
  }
  natureName(type){
    let nature = {
      text:this.translate.instant(`nature.${type}`),
      icon:this.icons.declarer[type] 
    }
    return nature
  }
  selectNature(sinistre){
    this.formSinistre.controls['nature'].setValue(sinistre.cnId);
    this.selectedSinistre = sinistre;
  }
  isBlessuer(event){
    this.formSinistre.controls['blesseur'].setValue(event);
  }
  closePage(){
    window.top.close();
  }
  numberWithSpace(number){
    if(!!number){
      let spacePlacement = number.match(/.{1,2}/g);
      return spacePlacement.join(" ")
    } else {
      return ""
    }
  }
}
