import { SinistreDocumentModel } from "./sinistre-document.model";
import { SinistreNatureModel } from "./sinistre-nature.model";

export class SinistreModel{
    cpCallback: string;
    cpCircumstance: string;
    cpClaimNaturesList: Array<SinistreNatureModel> = [];
    cpClientIdentifier: string;
    cpContact: string;
    cpContractIdentifier: string;
    cpDocumentList: Array<SinistreDocumentModel> = [];
    cpId: string;
    cpIsBodilyInjury: boolean;
    cpOccurenceDate: string;
    cpStatus: string;
}