import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
    private authentification = new Subject();
    userAuthentification = this.authentification.asObservable();
    constructor( private httpClient: HttpClient) {}
    setAuthentificationService(value){
      this.authentification.next(value)
    }
}
