import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimPostService extends GenericService<any> {
  baseUrl = environment.BASE_URL
  constructor( private httpClient: HttpClient) {
    
    super(httpClient,'ClaimPost/')
   }
   getClaimsNatures(){
      return this.httpClient.get<any>(this.baseUrl +`ClaimPost/GetClaimsNatures`, super.prepareHeader());
    
   }
   getFollowedClaims(clientId, contractId){
      return this.httpClient.get<any[]>(this.baseUrl +`ClaimPost/GetClaims/${clientId}/${contractId}`, super.prepareHeader());
   }
   getPhoneNumber(contractId,clientId){
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache'});
      return this.httpClient.get(this.baseUrl +`ClaimPost/GetPhone/${contractId}/${clientId}`, { headers,  responseType: 'text'} );
   }
}
