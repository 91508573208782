import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
  styleUrls: ['./information-popup.component.scss']
})
export class InformationPopupComponent extends DialogContentBase  implements OnInit {
  constructor(public dialog: DialogRef,public translate: TranslateService) {
    super(dialog); 
   }
   firstClick=0;
   @Input() pageName='';
  ngOnInit(): void {
  }
  clickOut() {
    this.firstClick++
   if(this.firstClick!=1) this.dialog.close();
  }
  close(){
    this.dialog.close();
  }
}
