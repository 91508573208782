import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthentificationService } from "./authentification.service";

@Injectable()
export class AuthentificationInterceptor implements HttpInterceptor{
    constructor(private authService : AuthentificationService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.getUser(); 
        const token = (currentUser) ? currentUser.authentificationId : null;
        if (currentUser && currentUser.authentificationId) {
            req = req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
          }
        return next.handle(req); 
    }
}