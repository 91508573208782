export const PREDECLARATION_STEPS = {
    predeclaration:{label:"Déclaration",id:0,text:"01",isValid: true},
    transmission:{label:"Transmission des documents",id:1,text:"02",isValid: true},
    confirmation:{label:"Confirmation déclaration",id:2,text:"03",isValid: true},
    finish:{label:"finish",id:3}
}

export const ARRAY_PREDECLARATION_STEPS = [
    {label:"Déclaration",id:0,text:"01",isValid: true},
    {label:"Transmission des documents",id:1,text:"02",isValid: true},
    {label:"Confirmation déclaration",id:2,text:"03",isValid: true},
]
export const ARRAY_PREDECLARATION_STEPS_WITHOUT_TITLE = [
    {label:"",id:0,text:"01",isValid: true},
    {label:"",id:1,text:"02",isValid: true},
    {label:"",id:2,text:"03",isValid: true},
]