import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService {
  requestNumber =0;
  constructor(private translate: TranslateService, private router:Router, private toastr: ToastrService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({ withCredentials: true });
    return next.handle(req).pipe(
      tap((evt) => {
        this.requestNumber = this.requestNumber +1;
        if (evt instanceof HttpResponse) {
          this.requestNumber = this.requestNumber -1;

        }
      }),
      catchError((err: any) => {
        if (err.status === 500 && err instanceof HttpErrorResponse) {
          this.requestNumber = this.requestNumber -1;
          this.toastr.error( this.translate.instant('shared.Server_Error'));
        } 
        if(err.status=== 400 && err instanceof HttpErrorResponse) {
          this.requestNumber = this.requestNumber -1;
          this.toastr.error( this.translate.instant('shared.Bad_Request_Error'));
        }
        if(err.status=== 401 && err instanceof HttpErrorResponse) {
          this.requestNumber = this.requestNumber -1;
          sessionStorage.removeItem('authentification');
          this.router.navigate(['401']);
        }
        if(err.status=== 404 && err instanceof HttpErrorResponse) {
          this.requestNumber = this.requestNumber -1;
        }
        throw err;
      })
    );
  }

}
