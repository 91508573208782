import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule } from "@progress/kendo-angular-grid";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideDirective } from './directive/click-outside.directive';



@NgModule({
  declarations: [ClickOutsideDirective],
  imports: [
    CommonModule,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    DateInputsModule,
    LayoutModule,
    LabelModule,
    DropDownsModule,
    FormsModule,
    PopupModule,
    TranslateModule,
    GridModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [InputsModule,
    ButtonsModule,
    DialogsModule,
    FormsModule,
    ReactiveFormsModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    PopupModule,
    TranslateModule,
    GridModule,
    ClickOutsideDirective
  ],
  providers:[TranslateService]
})
export class SharedModule { }
