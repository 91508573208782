export const ICONS={
    phone: "/assets/img/support.png",
    logo: "/assets/img/icons/harmonie/logo.svg",
    not_authorized: "/assets/img/icons/harmonie/401.svg",
    back_arrow: "/assets/img/icons/harmonie/BackArrow.svg",
    tree_small_icon: "/assets/img/LittleIcon.svg",
    tree_big_icon: "/assets/img/BigIcon.svg",
    degats: "/assets/img/degats.png",
    notification: "/assets/img/icons/harmonie/396.svg",
    delete:"/assets/img/icons/3354.svg",
    upload_gray:"/assets/img/icons/3355.svg",
    upload:"/assets/img/icons/harmonie/upload-color.svg",
    doc_gray:"/assets/img/icons/harmonie/document.svg",
    doc:"/assets/img/icons/3357.svg",
    casque:"/assets/img/icons/harmonie/516.svg",
    trash:"/assets/img/icons/3360.svg",
    help:"/assets/img/icons/help.svg",
    call:"/assets/img/icons/harmonie/call.svg",
    error:"/assets/img/icons/3361.svg",
    dommageAuxBiens:'/assets/img/icons/dommage_aux_bien.svg',
    respCivile:'/assets/img/icons/resp_civile.svg',
    sinistre:{
        DEA:"/assets/img/icons/harmonie/3352.svg",
        ECL:"/assets/img/icons/harmonie/3347.svg",
        INC:"/assets/img/icons/harmonie/3348.svg",
        DEL:"/assets/img/icons/harmonie/3350.svg",
        VOL:"/assets/img/icons/harmonie/3351.svg",
        AUT:"/assets/img/icons/harmonie/3349.svg",
    },
    declarer:{
        DEA:"/assets/img/icons/3344.png",
        ECL:"/assets/img/icons/3346.svg",
        INC:"/assets/img/icons/3341.png",
        DEL:"/assets/img/icons/3342.png",
        VOL:"/assets/img/icons/3343.png",
        AUT:"/assets/img/icons/3345.png",
    },
    gray:{
        DEA:"/assets/img/icons/gray-icons/water.svg",
        ECL:"/assets/img/icons/gray-icons/clim.svg",
        INC:"/assets/img/icons/gray-icons/incendie.svg",
        DEL:"/assets/img/icons/gray-icons/elect.svg",
        VOL:"/assets/img/icons/gray-icons/vol.svg",
        AUT:"/assets/img/icons/gray-icons/other.svg",
    }
}