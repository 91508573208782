export class FileToUpload{
    id?: string;

    Indexes: any;

    Pages: Page[];
}
export class Page{
    Content: string;

    fileName: string;

    DocumentType: string;

    Mime?: string;

}