<nav class="navbar navbar-expand-md navbar-light d-flex justify-content-between bg-color" #myNavbar>
    <div>
        <img class="vyv-logo" [src]="icons.logo" />
    </div>
    <div (click)="navbar()">
        <div  class="btn btn-primary menu-button">
            <span class="k-icon  k-icon-md menu-icon" [ngClass]="navbarIsOpen ? 'k-i-x':'k-i-menu'" ></span>{{ 'navbar.menu' | translate }}</div>
    </div>
</nav>
<div id="mySidenav" class="sidenav">
    <div class="nav-title" (click)="openInformationPopup('condition')">{{ 'navbar.condition' | translate }}</div>
    <!-- <div class="nav-title" (click)="openInformationPopup('cookies')">{{ 'navbar.cookies_hm' | translate }}</div> -->
    <div class="nav-title" (click)="openInformationPopup('cookies')">{{ 'navbar.cookies_hm' | translate }}</div>
    <!-- <div class="nav-title" (click)="openInformationPopup('politique')">{{ 'navbar.politique_hm' | translate }}</div> -->
    <!-- <div class="nav-title" (click)="openInformationPopup('politique')">{{ 'navbar.politique_vyv' | translate }}</div> -->
    <div class="nav-title" (click)="openInformationPopup('mentions')">{{ 'navbar.mentions' | translate }}</div>
</div>
<div kendoDialogContainer></div>
<div class="d-grid" >
    <div *ngIf="navbarIsOpen" class="grey-screen"></div>
    <div class="row page-container" [ngClass]="{'hide-overflow':isMobile}"
        (window:resize)="onResize($event.target.innerWidth)">

        <p class="page-title card-text " *ngIf="isDeclarationPage">{{ 'pre_declaration.title' | translate }} </p>
        <div [ngClass]="isDeclarationPage ? 'page-content' : 'page-content-suivie'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>