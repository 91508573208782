import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SinistreSubjectService {
  private sinistre = new BehaviorSubject(null);
  newSinistre = this.sinistre.asObservable();
  private declarationPage = new BehaviorSubject(null);
  isDeclarationPage = this.declarationPage.asObservable();
  private sinistreTypes = new BehaviorSubject(null);
  listSinistres = this.sinistreTypes.asObservable();
  constructor() { }

  changeSinistreValue(value){
    this.sinistre.next(value)
  }
  setSenistresTypes(value){
    this.sinistreTypes.next(value)
  }
  setPageIsDeclaration(value){
    this.declarationPage.next(value)
  }
}
