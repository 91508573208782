import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SinistreSubjectService } from 'src/app/Modules/sinistre-declaration/sinistre-subject.service';
import { environment } from 'src/environments/environment';
import { TARGETS } from '../enum/target';
import { GenericService } from './generic.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService extends GenericService<any> {
  baseUrl = environment.BASE_URL;

  constructor( private httpClient: HttpClient,private route: Router, private sharedDataService: SharedDataService, private sinistreSubjectService: SinistreSubjectService) {
    
    super(httpClient,'Authentification/')
  
  }
  authentification(){
    this.route.events.subscribe((data) => {
      if (data instanceof ActivationStart) {
        if(!!data.snapshot.params.id){
          this.httpClient.get<any[]>(this.baseUrl + `Authentification/${data.snapshot.params.id}`, super.prepareHeader()).pipe(take(1)).subscribe(result=> {
              sessionStorage.setItem('authentification', JSON.stringify(result));
              this.sharedDataService.setAuthentificationService(result); 
              if(result['target'] === TARGETS.declarer){
                this.sinistreSubjectService.setPageIsDeclaration(true);
               this.route.navigate(['declaration']) ;
              } 
              else {
                this.sinistreSubjectService.setPageIsDeclaration(false)
                this.route.navigate(['sinistre'])
              }
            }, error => {
              this.sharedDataService.setAuthentificationService(null);
              this.route.navigate(['401']);
            })
        }
      }
    });
  }
  getUser(){
    return JSON.parse(sessionStorage.getItem('authentification')) || null;
  }
  isConnected(){
    return !!JSON.parse(sessionStorage.getItem('authentification'));
  }
}
