
<div class="card"(window:resize)="onResize($event.target.innerWidth)">
    <div class="loading-style" *ngIf="isLoading"> 
        <img src="/assets/img/loading.gif" />
      </div>
    <div class="card-body page-container" >
        <div class="title" >{{ 'pre_declaration.pre_declaration' | translate }}</div>
        <hr>
        <div class="row">
            <div class="col-12" [ngClass]=" isMobile?'page-content-mobile': 'page-content' ">
            <div class="urgence" >* {{ 'shared.required' | translate }}</div>
        <form [formGroup]="formSinistre">
            <kendo-formfield>
                <div class="row">
                    <div class="col-xs-12 col-md-4 mt-1">

            <label class="paragraphe-title mb-1">{{ 'pre_declaration.date' | translate }} <span class="urgence" >*</span></label>
                        <kendo-datepicker class="w-100" formControlName="date" [max]="today" format="dd/MMM/yyyy">
                            <kendo-datepicker-messages
                            today="Aujourd'hui"
                          ></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <kendo-formerror class="reg-font" *ngIf="!formSinistre.controls['date'].value &&  (formSinistre.controls['date'].touched || submitted)" >
                            {{ 'shared.field' | translate }}  {{ 'pre_declaration.date' | translate | lowercase }}
                            {{ 'shared.required_field' | translate }}
                        </kendo-formerror>
                    </div>
                </div>
            </kendo-formfield>
            <kendo-formfield>

                <div class="row mt-2">
                    <label class="paragraphe-title">{{ 'pre_declaration.nature_sinistre' | translate }} <span class="urgence" >*</span></label>
                    <div class="col-lg-4 col-md-6 mt-1 field " *ngFor="let sinistre of sinistre_types" >
                        <div 
                        [ngClass]="{ 'selected-nature': !!sinistre && !!selectedSinistre && selectedSinistre.cnId === sinistre.cnId}"
                        class="input-border"
                        (click)="selectNature(sinistre)">
                        <input   formControlName="nature" value="{{sinistre.cnId}}" kendoRadioButton   type="radio"/>
                        <img  [src]="natureName(sinistre.cnCode).icon" width="18" class="icon"/>
                        <span class="nature-text" style="text-align: end;" >{{natureName(sinistre.cnCode).text}}</span>
                    </div>
                </div>
                <kendo-formerror class="reg-font" *ngIf="formSinistre.controls['nature'].invalid && submitted" >  {{ 'shared.field' | translate }}  {{ 'pre_declaration.nature_sinistre' | translate | lowercase}}
                    {{ 'shared.required_field' | translate }}
                </kendo-formerror>
                
            </div>
            </kendo-formfield>

            <kendo-formfield>
                <div class="row mt-2">
                    <label class="paragraphe-title"> {{ 'pre_declaration.nature_dommages' | translate }} <span class="urgence" >*</span></label>
                    <div class="col-12 mt-1">
                        <textarea class="form-control reg-font w-100" rows="2" formControlName="circonstances" [attr.maxLength]="500"></textarea>
                        <div class="character-counter">
                            <span>{{formSinistre.value.circonstances ? formSinistre.value.circonstances.length : 0}}</span>
                            <span>/500</span>
                        </div>
                        <kendo-formerror class="reg-font" *ngIf="(formSinistre.controls['circonstances'].invalid || formSinistre.controls['circonstances'].value.trim().length===0) && (formSinistre.controls['circonstances'].touched || submitted)" >{{ 'shared.field' | translate }}  {{ 'pre_declaration.nature_dommages' | translate | lowercase }}
                            {{ 'shared.required_field' | translate }}</kendo-formerror>
                            
                        </div>
                    </div>
                </kendo-formfield>
            
                <kendo-formfield>
                    <div class="row mt-2">
                        <label class="paragraphe-title">{{ 'pre_declaration.is_blesse' | translate }}<span class="urgence" >*</span> </label>
                        <div class="col-6 mt-1">
                            <div class="input-border"  [ngClass]="{ 'selected-nature': formSinistre.value.blesseur==='true'}" (click)="isBlessuer('true')">
                                <input type="radio" #terms kendoRadioButton formControlName="blesseur" value="true" />
                                <kendo-label class="k-radio-label reg-font" text="{{ 'shared.yes' | translate }}"></kendo-label>
                    </div>
                    
                </div>
                <div class="col-6 mt-1">
                    <div class="input-border"  [ngClass]="{ 'selected-nature': formSinistre.value.blesseur==='false'}" (click)="isBlessuer('false')">
                        <input type="radio" #terms kendoRadioButton formControlName="blesseur" value="false" />
                        <kendo-label class="k-radio-label reg-font" text="{{ 'shared.no' | translate }}"></kendo-label>
                    </div>
                </div>
                <kendo-formerror class="reg-font" *ngIf="formSinistre.controls['blesseur'].invalid && submitted" >{{ 'shared.field' | translate }}  {{ 'pre_declaration.is_blesse' | translate }}
                    {{ 'shared.required_field' | translate }}</kendo-formerror>
                </div>
            </kendo-formfield>

            <kendo-formfield>
                <div class="row mt-2">
                    <label class="paragraphe-title">{{ 'pre_declaration.contact' | translate }} <span class="phone">{{numberWithSpace(userInformation?.beneficPhone)}}</span></label>
                    <span class="phone-contact">{{ 'pre_declaration.appel_phone' | translate }}</span>
                    <div class="col-12 mt-1">
                        <input type="text" (keyup)="deleteNotNumber($event)" placeholder="{{ 'pre_declaration.phone_number' | translate }}"  formControlName="contact" class="form-control w-100 reg-font" rows="1"/>
                    </div>
                    </div>
            </kendo-formfield>
        </form>
    </div>
</div>
<div class="row">
    <div class="col-12 d-flex justify-content-between">
        <button type="button" class="btn btn-outline-primary cancel-button reg-font" (click)="closePage()" > {{ 'shared.cancel' | translate }} </button>
        <button type="button" class="btn btn-primary next-button reg-font" (click)="goToStep(1)"> {{ 'shared.next' | translate }} </button>
    </div>
</div>
    </div>
</div>