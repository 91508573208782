export class DocumentModel{
    CpdId: string;
    CpdFilename: string;
    CpdClaimPostId: string;
    CpdDocumentTypeId: string;
    CpdDocumentTypeDetailId: string;
    CpdCreatedDate: Date;
    CpdCreatedBy: string;
}
export class ClaimDocumentModel{
    Id: string;
    fileName: string;
}
export const documentToSave = (content, fileName): any => {
    return { src: 'data:application/' + ';base64,' + content , fileName: fileName };
  };
