import { Component } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router, RoutesRecognized } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LANGUAGE_LIST } from './shared/dictionnary/language-list';
import { AuthentificationService } from './shared/service/authentification.service';
import { SharedDataService } from './shared/service/shared-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = environment.ProductTitle;
  language_list = LANGUAGE_LIST;
  constructor(private translate: TranslateService, private authentificationService: AuthentificationService) {  
    translate.setDefaultLang(this.language_list.french);
    translate.use(this.language_list.french);
    this.authentificationService.authentification();    
  }



}
