<div class="row page-container" (window:resize)="onResize($event.target.innerWidth)"  #scroll>
    <div class="col-xl-9 col-xs-12 card-padding" [ngClass]="{'w-100' : currentStep==steps_list.finish.id}">
      <kendo-stepper *ngIf="currentStep !=steps_list.finish.id"
      [steps]="steps"
      stepType="full"
      [(currentStep)]="currentStep"
      class="stepColor"
      [linear]="false"
      (activate)="stepChange($event)"
      >
  </kendo-stepper>
  <div *ngIf="isMobile" class="right-icons">
        <div class="right-icon" (click)="scrollBottom()">
            <img [src]="icons.casque" width="25" />
        </div>
        <div style="display: none;" class="phone-number right-icon"  (click)="scrollBottom()">
        <img [src]="icons.help" alt="Error"  width="25"  />
        </div>
  </div>
  <div class="form-content" [ngClass]=" isMobile && currentStep!==steps_list.finish.id ?'page-content-mobile': 'page-content' " >
    <app-pre-declaration [claimPhoneNumber]="claimPhoneNumber" (stepTochange)="receiveStep($event)" [formSinistre]="form" [userInformation]="userInformation" *ngIf="currentStep ==steps_list.predeclaration.id"></app-pre-declaration>
    <app-transmission-documents  (stepTochange)="receiveStep($event)" [formSinistre]="form" *ngIf="currentStep ==steps_list.transmission.id"></app-transmission-documents>
    <app-confirmation-sinistre [claimPhoneNumber]="claimPhoneNumber" [screenWidth]="screenWidth" (stepTochange)="receiveStep($event)" [formSinistre]="form" [userInformation]="userInformation" *ngIf="currentStep ==steps_list.confirmation.id"></app-confirmation-sinistre>
    <app-confirmation *ngIf="currentStep ==steps_list.finish.id"></app-confirmation>
</div>
    </div>
    <div class="col-xl-3 col-xs-12" [ngClass]=" isMobile?'info-card-mobile': 'info-card' " *ngIf="currentStep !=steps_list.finish.id">
        <div class="card">
            <div class="card-body reg-font" [ngClass]="{'card-max-width':!isMobile}">
                <div class="d-flex justify-content-start">
                    <div>
                        <img [src]="icons.casque" width="25" class="icon-img" />
                    </div>
                    <div class="contact-info">
                        <b>{{ 'information.urgency'| translate }}</b> ou <b>{{ 'information.question'| translate }}</b>
                        <span>{{ 'information.contact'| translate }}</span>
                    </div>
                </div>
                <div class="phone-number">
                <img [src]="icons.call" alt="Error" width="18" class="icon-img" />
                <b> {{claimPhoneNumber}}</b>
                </div>
            </div>
        </div>
        <div style="display: none;" class="card mt-3">
            <div class="card-body reg-font" [ngClass]="{'card-max-width':!isMobile}">
                <img [src]="icons.help" width="25" class="icon-img" />
                {{ 'information.conseils'| translate }} <br *ngIf="screenWidth>1330"> 
                <span class="comment" [ngClass]="{'padding-left-30':screenWidth>1330}" >
                    {{ 'information.sinistre'| translate }}
                </span> 
            </div>
        </div>
    </div>
</div>