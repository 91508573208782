import { registerLocaleData } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ICONS } from 'src/app/shared/enum/icon';
import { ClaimPostService } from 'src/app/shared/service/claim-post-service';
import { ContractService } from 'src/app/shared/service/contract.service';
import { SinistreSubjectService } from '../sinistre-subject.service';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-confirmation-sinistre',
  templateUrl: './confirmation-sinistre.component.html',
  styleUrls: ['./confirmation-sinistre.component.scss']
})
export class ConfirmationSinistreComponent implements OnInit {
  isLoading = false;
  documents = [];
  isMobile=false;
  @Input() screenWidth;
  @Input()  claimPhoneNumber;
  isInjury = this.translate.instant('shared.no');
  currentSinistre = null;
  icons = ICONS;
  @Input() userInformation;
  @Output() stepTochange = new EventEmitter<number>();
  constructor(private sinistreSubjectService:SinistreSubjectService, private translate: TranslateService, private claimPostService: ClaimPostService, private contractService: ContractService) { }

  ngOnInit(): void {
    this.getSinistre();
    this.onResize(window.innerWidth);  
  }
  onResize(event) {
    this.isMobile = event<1200;
   }
  goToStep(index){
    this.stepTochange.emit(index)
  }
  getSinistre(){
    this.sinistreSubjectService.newSinistre.subscribe(result=>{
      this.currentSinistre = result;
      if(!!this.currentSinistre)
      {
        this.documents = this.currentSinistre.cpDocumentList;
        (this.currentSinistre.cpIsBodilyInjury)  ? this.isInjury =  this.translate.instant('shared.yes') : this.isInjury= this.translate.instant('shared.no');
      }
    })
  }
  updateClaim(){
    this.isLoading = true;
    this.currentSinistre.cpStatus = "COMPLETED";
    this.currentSinistre.confirmationMail = this.userInformation.beneficMail;
    this.sinistreSubjectService.changeSinistreValue(this.currentSinistre);
    this.claimPostService.update(this.currentSinistre).subscribe(result=>{
      this.isLoading = true;
      this.goToStep(3)
    }, error => {
      this.isLoading =false
    })
  }

  natureName(type){
    let nature = {
      text:this.translate.instant(`nature.${type}`),
      icon:ICONS.gray[type] 
    }
    return nature
  }
  trackByItem(index, item): string {
    return item;
  }
  numberWithSpace(number){
    if(!!number){
      let spacePlacement = number.match(/.{1,2}/g);
      return spacePlacement.join(" ")
    } else {
      return ""
    }
  }
}
