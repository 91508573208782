import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { ICONS } from 'src/app/shared/enum/icon';
import { FileToUpload, Page } from '../../../shared/models/file-upload.model';
@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent extends DialogContentBase  implements OnInit {
  isMobile = false;
  fileName :string;
  icons = ICONS;
  private fileSize: any;
  public errorSize: boolean=false;
  public enter: boolean=false;
  @ViewChild('file-input') myDiv: ElementRef<HTMLElement>;
  fileToUpload:any;
  lastTarget: EventTarget;
  FileSelected=false;
  uploadedFile:FileToUpload
  constructor(public dialog: DialogRef) {
    super(dialog); 
   }

  ngOnInit(): void { 
    this.onResize(window.innerWidth);  
  window.addEventListener("dragleave", (e) =>{
    e.preventDefault();
   
  });
  
  window.addEventListener("dragover", (e) =>{
    e.preventDefault();
  });
  
  window.addEventListener("drop", (e) =>{
    e.preventDefault();
   
    if (e.dataTransfer.files.length == 1) {
      this.fileToUpload=e.dataTransfer.files[0]
      this.fileName=e.dataTransfer.files[0].name;
      this.fileSize=e.dataTransfer.files[0].size;
      const reader = new FileReader();
      reader.readAsDataURL( this.fileToUpload);
      reader.onload = () => {
      this.fileToUpload.src = reader.result as string;
      this.fileToUpload.extension = this.fileToUpload.name.split('.').pop();
      this.setFileToUpload(this.fileToUpload)
     
    }
    }
  });
  }

  public onCancelAction(): void {
    this.dialog.close();
  }
  getFileInfo(value: any) {
    this.FileSelected=true;
    this.fileToUpload=value.target.files[0]
    this.fileName = value.target.files[0].name;
    this.fileSize = value.target.files[0].size;
    const reader = new FileReader();

    reader.readAsDataURL( this.fileToUpload);
    reader.onload = () => {
    this.fileToUpload.src = reader.result as string;
    this.fileToUpload.extension = this.fileToUpload.name.split('.').pop();
    this.setFileToUpload(this.fileToUpload)  
}}
setFileToUpload(file:any){
  let fileToUpload =new FileToUpload();
  fileToUpload.Indexes={ a: 'b' }
  let page= new Page()
  page.Content=this.getFileContent(file.src, "base64,")
  page.DocumentType= file.extension
  page.fileName= file.name
  page.Mime="application/octet-stream"
  fileToUpload.Pages=[]
  fileToUpload.Pages.push(page)
  this.uploadedFile=fileToUpload;
  this.errorSize = false;
}
getFileContent(src:string,ext:string){
    return  src.split(ext).pop();
}
public transfertDocuments(){
  this.errorSize=false;
  if (this.checkSize()){
    this.dialog.close(this.uploadedFile);
  }
  else{
     this.errorSize=true;
  }
}
public checkSize(): boolean{
  if (this.fileSize>20000*1024){
    return false;
  }
  else {
    return true;
  }
}
onResize(event) {
  this.isMobile = event<1200;
 }
removeDoc(){
  this.fileName='';
  this.fileToUpload=null;
  this.uploadedFile=null;

}
selectFile(event){
  
  event.stopPropagation();
  let el: HTMLElement = document.getElementById('file-input');
  el.click()
    
}
hover(e){
  this.enter=e;
}


}
