import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ICONS } from 'src/app/shared/enum/icon';
import { AuthentificationService } from 'src/app/shared/service/authentification.service';
import { SinistreSubjectService } from '../sinistre-declaration/sinistre-subject.service';
import { InformationPopupComponent } from './information-popup/information-popup.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  title=''
  authentification = this.authentificationService.getUser();
  isDeclarationPage= null;
  icons = ICONS;
  isMobile=false;
  navbarIsOpen= false;
  width:number = null;
  data = [
    {
      text: "My Profile",
    },
    {
      text: "Log Out",
    },
  ];
  constructor(public translate: TranslateService, private dialogService: DialogService, private authentificationService:AuthentificationService,private route: ActivatedRoute,private sinistreSubjectService: SinistreSubjectService) {}

  ngOnInit(): void {
  this.onResize(window.innerWidth);
  this.titleName();
  }
  titleName(){
    this.sinistreSubjectService.isDeclarationPage.subscribe(result=>{
      this.isDeclarationPage = result;
      if(result !== null){
        this.title = result ? this.translate.instant('pre_declaration.title') : this.translate.instant('suivi_sinistre.title');
      } else {
        this.title=''
      }
    })
  return this.title;
  }
  onResize(event) {
    this.isMobile = event>1025 && event<1110;
 }
 openInformationPopup(page) {
  const dialogRef = this.dialogService.open({
      content: InformationPopupComponent ,
      maxHeight:500,
      maxWidth:700,
  });
  dialogRef.content.instance.pageName = page;
}
 navbar(){
  this.navbarIsOpen = !this.navbarIsOpen;
  (this.navbarIsOpen) ? document.getElementById("mySidenav").style.width = "300px" :  document.getElementById("mySidenav").style.width = "0";
 }
}
