export const NATURESINISTRE={
    vol:"VOL",
    ecl: "ECL",
    inc:"INC",
    aut: "AUT",
    del: "DEL",
    dea: "DEA"
}

export const NATURESINISTRE_WITH_NAME= [
    {name:"Vol",code:"VOL"},
    {name:"Evénement climatique",code: "ECL"},
    {name:"Incendie",code:"INC"},
    {name:"Autres",code: "AUT"},
    {name:"Dommage électrique",code: "DEL"},
    {name:"Dégât des eaux ",code: "DEA"}
]
