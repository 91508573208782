import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import '@progress/kendo-angular-intl/locales/fr/all';
import { SinistreDeclarationRoutingModule } from './sinistre-declaration-routing.module';
import { SinistreDeclarationComponent } from './sinistre-declaration.component';
import { TeledeclarationComponent } from './pre-declaration/pre-declaration.component';
import { TransmissionDocumentsComponent } from './transmission-documents/transmission-documents.component';
import { ConfirmationSinistreComponent } from './confirmation-sinistre/confirmation-sinistre.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';


@NgModule({
  declarations: [SinistreDeclarationComponent, TeledeclarationComponent, TransmissionDocumentsComponent, ConfirmationSinistreComponent, ConfirmationComponent, AddDocumentComponent],
  imports: [
    CommonModule,
    SharedModule,
    SinistreDeclarationRoutingModule
  ],
  providers: [
    CldrIntlService,
    {
        provide: IntlService,
        useExisting: CldrIntlService
    },
    {
        provide: LOCALE_ID,
        useValue: 'fr-FR'
    }
],
  exports: [ TransmissionDocumentsComponent],
  entryComponents: [AddDocumentComponent]
})
export class SinistreDeclarationModule { }
