import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimPostDocumentService extends GenericService<any> {

  constructor( private httpClient: HttpClient) {
    
    super(httpClient,'ClaimPostDocument/')
   }
}