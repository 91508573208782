import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification.guard';
import { LoadingComponent } from './loading/loading.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const routes: Routes = [
  {
    path: "declaration",
    loadChildren: () =>import('../sinistre-declaration/sinistre-declaration.module').then(m => m.SinistreDeclarationModule),
    canActivate: [AuthentificationGuard]
  },
  {
    path: "auth/:id",
    component: LoadingComponent
  },
  {
    path: "sinistre",
    loadChildren: () =>import('../sinistres-list/sinistres-list.module').then(m => m.SinistresListModule),
    canActivate: [AuthentificationGuard]
  },
  {
    path: "",
    component: LoadingComponent
  },
  {
    path: "401",
    component: NotAuthorizedComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
