<div class="card mb-2" (window:resize)="onResize($event.target.innerWidth)">
    <div class="card-body page-container">
        <div class="card-header flex-wrap bg-transparent col-12 d-flex justify-content-between align-items-center p-0">
            <b class="title">{{ 'documents_transmission.title'| translate }}</b>
            <button kendoButton class="btn btn-primary next-button" (click)="openAddDocumentPopUp()">{{ 'documents_transmission.Ajouter_document'| translate }}</button>
        </div>
        <div class="notification d-flex align-items-center">
            <img [src]="icons.notification" alt="Error"/>
            <span class="notif-text" >{{ 'documents_transmission.notification'| translate }}</span>
        </div>
        <div *ngIf="!!documentsList && documentsList.length==0" class="col-12" [ngClass]=" isBigScreen?'big-screen-page-content': isMobile ? 'page-content-mobile' : 'page-content' ">
            <img src="assets/img/icons/harmonie/EmptyState.svg" alt="Error" class="center" />
            <div class="txt-center reg-font">{{ 'documents_transmission.Aucun_document_ajouté'| translate }}</div>
        </div>
        <div *ngIf="!!documentsList && documentsList.length!=0" class="col-12" [ngClass]=" isBigScreen?'big-screen-page-content': isMobile ? 'page-content-mobile' : 'page-content' ">
            <div class="doc-card card card-style" *ngFor="let doc of documentsList">
                <div class="card-body d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                     <img [src]="icons.doc_gray" alt="Error" class="doc-icon"  />
                        <span class="doc-title">
                            {{doc.cpdFilename || doc}}
                        </span>
                    </div>
                    <div *ngIf="doc.cpdId==null; else deleteButton">
                        <img class="loading-image" src="/assets/img/loading.gif" />
                    </div>
                    <ng-template #deleteButton>
                        <img class="cursor delete-btn-icon" (click)="open(doc.cpdId)" [src]="icons.delete" alt="Error" />
                    </ng-template>
                    
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-between mt-3">
                <button kendoButton class="btn btn-primary  cancel-button" [disabled]="isDisabledButton()|| uploadingFile" (click)="back()" >{{ 'shared.retour' | translate }}</button>
                <button kendoButton class="btn btn-outline-primary next-button" [disabled]="isDisabledButton() || uploadingFile" (click)="next()" type="button" disabled>{{ 'shared.next' | translate }} </button>
            </div>
        </div>

    </div>
</div>
<div kendoDialogContainer></div>
<kendo-dialog *ngIf="opened" (close)="close(false)" [minWidth]="250" [width]="450">
    <p class="delete-text reg-font">
        <img  class="delete-icon mb-3" [src]="icons.delete" alt="Error" />
        <br>
        {{'pre_declaration.suppression' | translate}}
    </p>
    <div class="row m-0">
        <div (click)="close(false)" class="col-6 cancel-btn p-0">
            <div  class="reg-font">{{'shared.no' | translate}}</div>
        </div>
        <div class="col-6 p-0">
            <button kendoButton class="delete-btn reg-font" type="button"(click)="close(true)">{{'shared.yes' | translate}}</button>
        </div>
    </div>
</kendo-dialog>