import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends GenericService<any> {
  baseUrl = environment.BASE_URL
  constructor( private httpClient: HttpClient) {
    
    super(httpClient,'Contract/')
   }
   getUserInformation(contractId,clientId){
    return this.httpClient.get<any>(this.baseUrl +`Contract/${contractId}/${clientId}`, super.prepareHeader());
  
 }
}
